import styles from "./PersonalInfo.module.scss";
import Input from "@/components/Input/Input";
import { User } from "@/models/user";

interface PersonalInfoProps {
    user?: User;
    isSanaiPRO: boolean;
    userIsPRO: boolean;
    handleInputChange: (newValue: any, key: string) => void;
}


const PersonalInfo = ({ user, isSanaiPRO, userIsPRO, handleInputChange }: PersonalInfoProps) => {
    return (
        <div className={styles.info}>
            <Input
                name="first-name"
                type="text"
                placeholder="Nombre"
                value={user?.first_name}
                onChange={(newValue) => handleInputChange(newValue, "first_name")}
            />
            <Input
                name="last-name"
                type="text"
                placeholder="Apellidos"
                value={user?.last_name}
                onChange={(newValue) => handleInputChange(newValue, "last_name")}
            />
            <Input
                name="email"
                type="email"
                placeholder="Email"
                value={user?.email}
                onChange={(newValue) => handleInputChange(newValue, "email")}
                disabled={userIsPRO}
            />
            {isSanaiPRO &&
                <Input
                    name="pro-code"
                    type="password"
                    placeholder={"Código PRO"}
                    value={user?.pro_code}
                    onChange={(newValue) => handleInputChange(newValue, "pro_code")}
                    disabled={!user?.email || userIsPRO}
                />
            }
        </div>
    );
};

export default PersonalInfo;