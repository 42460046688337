import PaymentController from "./Payment.controller";


const Payment = () => {
    PaymentController();

    return (
        <div>
            
        </div>
    );
}

export default Payment;
