import { combineReducers } from 'redux';
import { paymentSlice} from './payment.slice';

export const paymentReducer = combineReducers({
    payment: paymentSlice.reducer,
});

export const paymentActions = {
    ...paymentSlice.actions
};
