import { combineReducers } from 'redux';
import { userSlice } from './user.slice';

export const userReducer = combineReducers({
    user: userSlice.reducer,
});

export const userActions = {
    ...userSlice.actions
};
