const translationLanguagesOptions = [
    {value: 'EN-US', label: 'Inglés americano'},
    {value: 'EN-GB', label: 'Inglés británico'},
    {value: 'ES', label: 'Español'},
    {value: 'DE', label: 'Alemán'},
    {value: 'BG', label: 'Búlgaro'},
    {value: 'CS', label: 'Checo'},
    {value: 'ZH', label: 'Chino'},
    {value: 'KO', label: 'Coreano'},
    {value: 'DA', label: 'Danés'},
    {value: 'SK', label: 'Eslovaco'},
    {value: 'SL', label: 'Esloveno'},
    {value: 'ET', label: 'Estonio'},
    {value: 'FI', label: 'Finlandés'},
    {value: 'FR', label: 'Francés'},
    {value: 'EL', label: 'Griego'},
    {value: 'NL', label: 'Holandés'},
    {value: 'HU', label: 'Húngaro'},
    {value: 'ID', label: 'Indonesio'},
    {value: 'IT', label: 'Italiano'},
    {value: 'JA', label: 'Japonés'},
    {value: 'LV', label: 'Letón'},
    {value: 'LT', label: 'Lituano'},
    {value: 'NB', label: 'Noruego (Bokmål)'},
    {value: 'PL', label: 'Polaco'},
    {value: 'PT-BR', label: 'Portugués'},
    {value: 'PT-PT', label: 'Portugués'},
    {value: 'RO', label: 'Rumano'},
    {value: 'RU', label: 'Ruso'},
    {value: 'SV', label: 'Sueco'},
    {value: 'TR', label: 'Turco'},
    {value: 'UK', label: 'Ucraniano'}
];

export default translationLanguagesOptions;