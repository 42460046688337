import selectStyle from './Select.styles';
import ReactSelect, { components as DropdownComponents, DropdownIndicatorProps } from 'react-select';
import Arrow from '@/assets/icons/arrow.svg';

export interface SelectProps {
    label?: string;
    name?: string;
    options: { value: string; label: string }[];
    value?: string;
    onChange: (e: any) => void;
    [x: string]: any;
}

export const generateOptionsSelect = ({ opt }: { opt: string[] }) => {
    const newOptions = opt.map((e) => {
        return { label: e, value: e };
    });
    return newOptions ?? [];
};

const DropdownIndicator = (
    props: DropdownIndicatorProps
) => {
    return (
        <DropdownComponents.DropdownIndicator {...props}>
            <Arrow />
        </DropdownComponents.DropdownIndicator>
    );
};

const Select = ({ value, onChange, ...props }: SelectProps) => {
    return (
        <ReactSelect
            styles={selectStyle}
            value={value}
            components={{ DropdownIndicator }}

            {...props}
            onChange={onChange}
        />
    );
};

export default Select;
