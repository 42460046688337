import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { resetDiscount } from '@/store/discount/discount.slice';
import { postQuestionAnswerThunk } from '@/store/questionAnswer/questionAnswer.slice';
import { resetPayment } from '@/store/payment/payment.slice';
import { resetGeolocation } from '@/store/geolocation/geolocation.slice';
import { Features } from '@/utils/constants/features';
import { useAppDispatch } from '@/index';
import { selectQuestionAnswer } from '@/store/questionAnswer/questionAnswer.selectors';


const SuccessPrepaid = () => {
    const { feature, userId, payment_order } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();

    const questionAnswer = useSelector(selectQuestionAnswer);

    useEffect(() => {
        if (questionAnswer) {
            navigate(`/${Features.QUESTION_ANSWER}/chat/${questionAnswer.id}`);
        }
    }, [questionAnswer]);

    useEffect(() => {
        dispatch(resetDiscount());
        dispatch(resetPayment());
        dispatch(resetGeolocation());
        
        // Se crea el recurso en particular, según el Feature (cuidado userId y payment_order)
        if (feature === Features.QUESTION_ANSWER) {
            appDispatch(postQuestionAnswerThunk({ question_answer: { user_id: userId }, payment_order: payment_order ?? '' }));
        }
    }, []);

    return (
        <div></div>
    );
}

export default SuccessPrepaid;
