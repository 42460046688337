import { StudySections } from "@/utils/constants/wordsLimits";

/* 
- users_inputs:
    - id
    - user_id # nullable
    - text_type # enum ['congress_poster', 'book_chapter']
    - title
    - overview
    - study_type # enum ['review', 'research', 'clinical_case', 'care_plan']
    - other_inputs # json
    - status # enum ['in_progress', 'sent_to_ai', 'processed'] # TODO Es posible que se necesiten más estados en el futuro
    - created_at
    - updated_at
*/

export enum TextType {
    CONGRESS_POSTER = 'congress_poster',
    BOOK_CHAPTER = 'book_chapter'
}

export enum StudyType {
    REVIEW = 'review',
    RESEARCH = 'research',
    CLINICAL_CASE = 'clinical_case',
    CARE_PLAN = 'care_plan'
}

export enum UserInputStatus {
    IN_PROGRESS = 'in_progress',
    SENT_TO_AI = 'sent_to_ai',
    PROCESSED = 'processed'
}

export interface UserInput {
    id?: string;
    user_id?: string;
    text_type?: TextType;
    title?: string;
    overview?: string;
    study_type?: StudyType;
    other_inputs: {
        words: StudySections;
        [key: string]: any; // TODO
    };
    status?: UserInputStatus;
    created_at?: string;
    updated_at?: string;
}
