import TextArea from '@/components/TextArea/TextArea';
import styles from './Translations.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateTranslationOriginalText, updateTranslationTargetLanguage } from '@/store/translation/translation.slice';
import { selectTranslation } from '@/store/translation/translation.selectors';
import Select from '@/components/Select/Select';
import translationLanguagesOptions from '@/utils/constants/translationsLanguagesOptions';
import { useEffect, useState } from 'react';



export default function Translations() {
    const dispatch = useDispatch();

    const [defaultValue, setDefaultValue] = useState<string | undefined>(undefined);
    
    const translation = useSelector(selectTranslation);

    const handleInputChange = (text: string | undefined) => {
        dispatch(updateTranslationOriginalText(text));
    };

    const handleLanguageChange = (option: any) => {
        dispatch(updateTranslationTargetLanguage(option.value));
    };

    function findOptionByValue(options: any, targetValue: any) {
        return options.find(option => option.value === targetValue) || "none";
    }

    useEffect(() => {
        setDefaultValue(findOptionByValue(translationLanguagesOptions, translation.target_language));
    }, [translation.target_language]);

    return (
        <div className={styles.content}>
            <div className={styles.type}>
                {defaultValue && (
                    <Select
                        name="translation"
                        placeholder="Elige el idioma a traducir"
                        options={translationLanguagesOptions}
                        onChange={handleLanguageChange}
                        defaultValue={defaultValue}
                    />
                )}
            </div>
            <div className={styles.title}>
                <TextArea
                    name="translations"
                    placeholder="Introduzca el texto científico a traducir"
                    value={translation.original_text ?? ''}
                    onChange={(newValue) => handleInputChange(newValue)}
                />
            </div>
        </div>
    );
}
