import { combineReducers } from 'redux';
import { questionAnswerSlice} from './questionAnswer.slice';

export const questionAnswerReducer = combineReducers({
    questionAnswer: questionAnswerSlice.reducer,
});

export const questionAnswerActions = {
    ...questionAnswerSlice.actions
};
