import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface FormProgressState {
    currentStep: number;
    completedSteps: number[];
    termsAccepted: boolean;
}

export const initialState: FormProgressState = {
    currentStep: 1,
    completedSteps: [],
    termsAccepted: false,
};

export const formProgressSlice = createSlice({
    name: 'formProgress',
    initialState: initialState,
    reducers: {
        addStep: (state, action: PayloadAction<number>) => {
            if (!state.completedSteps.includes(action.payload)) {
                state.completedSteps.push(action.payload);
            }
        },
        removeStep: (state, action: PayloadAction<number>) => {
            state.completedSteps = state.completedSteps.filter(step => step !== action.payload);
        },
        resetSteps: (state) => {
            state.completedSteps = [];
        },
        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload < 1 ? 1 : action.payload;
        },
        setTermsAccepted: (state, action: PayloadAction<boolean>) => {
            state.termsAccepted = action.payload;
        },
    },
});

export const { addStep, removeStep, resetSteps, setCurrentStep, setTermsAccepted } = formProgressSlice.actions;
