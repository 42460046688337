import { i18n as i18nType, TFunction } from 'i18next';
import i18n from '@/utils/translator/i18n';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@/utils/constants/langs.constants';

function useTranslator(): {
    t: TFunction;
    i18n: i18nType;
    lang: string;
    setLang: (lang: string) => void;
} {
    const lang = localStorage.getItem('lang') ?? DEFAULT_LANG;

    const { t } = useTranslation();

    function setLang(_lang: string): void {
        localStorage.setItem('lang', _lang);

        if (i18n.language !== _lang) {
            // eslint-disable-next-line no-console
            i18n.changeLanguage(_lang).then(() => console.log(`Lang changed to: ${_lang}`));
        }
    }

    setLang(lang);

    return { t, i18n, lang, setLang };
}

export default useTranslator;
