import { combineReducers } from 'redux';
import { discountSlice } from './discount.slice';

export const discountReducer = combineReducers({
    discount: discountSlice.reducer,
});

export const discountActions = {
    ...discountSlice.actions
};
