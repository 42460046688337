{
    "translation": {
        "congress_poster": "Póster Congreso",
        "book_chapter": "Capítulo de libro",
        "question_answer": "Responder Tipo Test",
        "defense": "Impugnar Test",
        "scientific_text": "Redactar Trabajo Científico",
        "introduction": "Introducción",
        "objectives": "Objetivos",
        "methodology": "Metodología",
        "results": "Resultados",
        "discussion": "Conclusiones",
        "bibliography": "Bibliografía",
        "clinical_case": "Caso clínico",
        "text": "Texto libre",
        "review": "Revisiones bibliográficas. sistemáticas o literarias",
        "research": "Proyectos de investigación",
        "clinical_cases": "Casos clínicos",
        "care_plan": "Planes de cuidados"
    }
}
