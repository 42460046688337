import { combineReducers } from 'redux';
import { defenseSlice } from './defense.slice';

export const defenseReducer = combineReducers({
    defense: defenseSlice.reducer,
});

export const defenseActions = {
    ...defenseSlice.actions
};
