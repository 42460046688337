import { combineReducers } from 'redux';
import { formProgressSlice} from './formProgress.slice';

export const formProgressReducer = combineReducers({
    formProgress: formProgressSlice.reducer,
});

export const formProgressActions = {
    ...formProgressSlice.actions
};
