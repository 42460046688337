import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import QuestionsAnswers from './pages/QuestionsAnswers/QuestionsAnswers';
import Translations from './pages/Translations/Translations';
import RouteGuard from './utils/wrappers/RouteGuard';
import TextInfo from './pages/Form/TextInfo/TextInfo';
import StudyParams from './pages/Form/StudyParams/StudyParams';
import Checkout from './pages/Form/Checkout/Checkout';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Payment from './pages/Payment/Payment';
import Success from './pages/Success/Success';
import Error from './pages/Error/Error';
import Purchase from './pages/Purchase/Purchase';
import FeatureLayout from './layout/feature/FeatureLayout';
import { Features } from './utils/constants/features';
import Defense from './pages/Defense/Defense';
import Home from './pages/Home/Home';
import ScientificTextLanding from './pages/Home/landings/ScientificTextLanding';
import SuccessPrepaid from './pages/Success/SuccessPrepaid';


const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/ai-text" element={<ScientificTextLanding />} />
                    <Route path={`/${Features.SCIENTIFIC_TEXT}`} element={<FeatureLayout feature={Features.SCIENTIFIC_TEXT} />}>
                        <Route index element={<TextInfo />} />
                        <Route path="study-params" element={
                            <RouteGuard step={1} redirect={`/${Features.SCIENTIFIC_TEXT}`}>
                                <StudyParams />
                            </RouteGuard>
                        } />
                        <Route path="checkout" element={
                            <RouteGuard step={2} redirect={`/${Features.SCIENTIFIC_TEXT}/study-params`}>
                                <Checkout feature={Features.SCIENTIFIC_TEXT} />
                            </RouteGuard>
                        } />
                    </Route>
                    <Route path={`/${Features.QUESTION_ANSWER}`} element={<FeatureLayout feature={Features.QUESTION_ANSWER} />}>
                        <Route index element={<QuestionsAnswers />} />
                        <Route path="checkout" element={
                            <RouteGuard step={1} redirect={`/${Features.QUESTION_ANSWER}`}>
                                <Checkout feature={Features.QUESTION_ANSWER} />
                            </RouteGuard>
                        } />
                    </Route>
                    <Route path={`/${Features.DEFENSE}`} element={<FeatureLayout feature={Features.DEFENSE} />}>
                        <Route index element={<Defense />} />
                        <Route path="checkout" element={
                            <RouteGuard step={1} redirect={`/${Features.DEFENSE}`} >
                                <Checkout feature={Features.DEFENSE} />
                            </RouteGuard>
                        } />
                    </Route>
                    <Route path={`/${Features.TRANSLATION}`} element={<FeatureLayout feature={Features.TRANSLATION} />}>
                        <Route index element={<Translations />} />
                        <Route path="checkout" element={
                            <RouteGuard step={1} redirect={`/${Features.TRANSLATION}`}>
                                <Checkout feature={Features.TRANSLATION} />
                            </RouteGuard>
                        } />
                    </Route>
                    <Route path="/payment/:feature" element={<Payment />} />
                    <Route path="/purchase/:feature/:resource_id/:user_id/:price/:payment_order/:payment_type" element={<Purchase />} /> {/* TODO SECURIZAR! */}
                    <Route path="/success" element={<Success />} />
                    <Route path="/success-prepaid/:feature/:user_id/:payment_order" element={<SuccessPrepaid />} />
                    <Route path="/error" element={<Error />} />
                    {/* <Route path="/not-found" element={<NotFound />} /> */}
                </Routes>
            </Layout>
            <ToastContainer
                position="top-right"
                limit={3}
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </BrowserRouter>
    );
};

export default App;