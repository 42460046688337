import Loader from '@/components/Loader/Loader';
import { RootState } from '@/store/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Layout.module.scss';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {

    const isLoading = useSelector((state: RootState) => state.loader.setLoader.isLoading);

    return (
        <>
            <Loader isLoading={isLoading} />
            <Header />
            <div className={styles.container}>
                {children}
            </div>
            <Footer />
        </>
    );
};

export default Layout;
