export function isEmptyObject(obj: {}) {
    return Object.keys(obj).length === 0;
}

// Function to validate email format
export function isValidEmail(email: string): boolean {
    // Use a regular expression to validate email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

export function limitDecimalPlaces(num: number, decimalPlaces: number) {
    // log num and its type
    return parseFloat(num.toFixed(decimalPlaces));
}
