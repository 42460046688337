import { combineReducers } from 'redux';
import { geolocationSlice } from './geolocation.slice';

export const geolocationReducer = combineReducers({
    geolocation: geolocationSlice.reducer,
});

export const geolocationActions = {
    ...geolocationSlice.actions
};
