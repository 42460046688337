import { Geolocation } from '@/models/geolocation';
import { removeLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GeolocationState {
    isLoading: boolean;
    error: Error | undefined;
    geolocation: Geolocation | undefined;
}

export const initialState: GeolocationState = {
    isLoading: false,
    error: undefined,
    geolocation: undefined
};

export const getGeolocationThunk = createAsyncThunk<any, Geolocation, { rejectValue: Error }>(
    'Geolocation/getGeolocation',
    async (_, thunkApi) => {
        return fetch(process.env.IP_INFO_URL ?? '', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + process.env.IPINFO_ACCESS_TOKEN
            }
        })
            .then((res) => {
                console.log(res);
                return res.json() as Geolocation;
            })
            .catch((err) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const geolocationSlice = createSlice({
    name: 'geolocation',
    initialState: initialState,
    reducers: {
        setGeolocation: (state, action: PayloadAction<Geolocation>) => {
            state.geolocation = action.payload;
        },
        resetGeolocation: (state) => {
            state = initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGeolocationThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false), (state.error = undefined), (state.geolocation = payload);
            })
            .addCase(getGeolocationThunk.rejected, (state, { payload }) => {
                (state.isLoading = false), (state.error = payload), (state.geolocation = { country: 'ES' });
            });
    }
});

export const { setGeolocation, resetGeolocation } = geolocationSlice.actions;
