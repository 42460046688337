import styles from './ScientificTextLanding.module.scss';
import doctor from '@/assets/images/doctor.png';
import CheckIcon from '@/assets/icons/check.svg';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Features } from '@/utils/constants/features';

const TITLE = "Redacta tu trabajo científico en tan solo 1 día";
const DESCRIPTION = "La plataforma Nº1 de asesoramiento y apoyo de inteligencia artificial para facilitar y ayudar en la publicación de trabajos científicos de calidad a los sanitarios y en diferentes áreas formativas";
const BULLET_POINTS = [
    "Adaptación a estándares científicos oficiales",
    "Referencias bibliográficas detalladas",
    "Integración de múltiples fuentes y proveedores de IA",
    "Extensión de la redacción modificable",
    "Raṕido, en el mismo día",
    "Normalmente en menos de 1 hora"
];

const ScientificTextLanding = () => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery({ maxWidth: 480 });
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });

    const getFlexDirection = () => {
        if (isMobile || isTablet) {
            return 'flex-column';
        }

        return 'flex-row';
    };


    return (
        <div className={styles.container}>
            <div className={`${styles.content} ${getFlexDirection()}`}>
                {isTablet ? (
                    <>
                        <div className={styles.imageTitleRow}>
                            <div className={styles.image}>
                                <img src={doctor} alt="SANAI" />
                            </div>
                            <div className={styles.titleRight}>
                                <h1>
                                    {TITLE}
                                </h1>
                            </div>
                        </div>

                        <div className={styles.text}>
                            <div className={styles.description}>
                                {DESCRIPTION}
                            </div>
                            <div className={styles.bulletPoints}>
                                <ul>
                                    {BULLET_POINTS.map((bulletPoint, index) => (
                                        <li key={index}>
                                            <CheckIcon />{bulletPoint}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className={styles.button}>
                                <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${Features.SCIENTIFIC_TEXT}`)}>
                                    Comenzar
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.image}>
                            <img src={doctor} alt="SANAI" />
                        </div>

                        <div className={styles.text}>
                            <div className={styles.title}>
                                <h1>
                                    {TITLE}
                                </h1>
                            </div>
                            <div className={styles.description}>
                                {DESCRIPTION}
                            </div>
                            <div className={styles.bulletPoints}>
                                <ul>
                                    {BULLET_POINTS.map((bulletPoint, index) => (
                                        <li key={index}>
                                            <CheckIcon />{bulletPoint}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className={styles.buttons}>
                                <div className={styles.button}>
                                    <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${Features.SCIENTIFIC_TEXT}`)}>
                                        Comenzar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ScientificTextLanding;