import { useAppDispatch } from '@/index';
import { selectDefense } from '@/store/defense/defense.selectors';
import { selectDiscount } from '@/store/discount/discount.selectors';
import { selectGeolocation } from '@/store/geolocation/geolocation.selectors';
import { getGeolocationThunk } from '@/store/geolocation/geolocation.slice';
import { selectPayment, selectPaymentMethod } from '@/store/payment/payment.selectors';
import { postPaymentRequestThunk } from '@/store/payment/payment.slice';
import { selectResourcePROPrice, selectResourcePrice } from '@/store/price/resourcePrice.selectors';
import { selectQuestionAnswer } from '@/store/questionAnswer/questionAnswer.selectors';
import { selectTranslation } from '@/store/translation/translation.selectors';
import { selectUser, selectUserIsPRO } from '@/store/user/user.selectors';
import {
    selectUserInput,
} from '@/store/userInput/userInput.selectors';
import { Features } from '@/utils/constants/features';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const PaymentController = () => {
    const { feature } = useParams();

    const navigate = useNavigate();

    const appDispatch = useAppDispatch();

    const user = useSelector(selectUser);
    const payment = useSelector(selectPayment);
    const discount = useSelector(selectDiscount);
    const userIsPRO = useSelector(selectUserIsPRO);
    const userInput = useSelector(selectUserInput);
    const questionAnswer = useSelector(selectQuestionAnswer);
    const translation = useSelector(selectTranslation);
    const defense = useSelector(selectDefense);
    const geolocation = useSelector(selectGeolocation);
    const paymentMethod = useSelector(selectPaymentMethod);
    const resourcePrice = useSelector(selectResourcePrice);
    const resourcePROPrice = useSelector(selectResourcePROPrice);

    const [resource, setResource] = useState<any>(undefined);

    useEffect(() => {
        console.log("PAYMENT");
        if (!resource) {
            appDispatch(getGeolocationThunk({}));
            return;
        }

        if (payment) {
            let price = userIsPRO ? resourcePROPrice ?? 0 : resourcePrice ?? 0;

            if (discount) {
                price = discount.new_price;
            }

            if (price === 0) {
                navigate(`/purchase/${feature}/${resource.id}/${user.id}/0/${payment.payment_order}/Free`);
                return;
            }

            if (payment.type === 'redsys') {
                // creamos un form con los campos necesarios y lo enviamos
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = payment.data.redsys_url;
                // form.target = '_blank';
                form.style.display = 'none';
                form.innerHTML = `
                    <input type="hidden" name="Ds_SignatureVersion" value="${payment.data.Ds_SignatureVersion}">
                    <input type="hidden" name="Ds_MerchantParameters" value="${payment.data.Ds_MerchantParameters}">
                    <input type="hidden" name="Ds_Signature" value="${payment.data.Ds_Signature}">
                `;
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            } else if (payment.type === 'stripe') {
                // redirect to payment.data.session_url
                window.location.href = payment.data.session_url;
            }
        }
    }, [payment]);

    useEffect(() => {
        console.log("RESOURCE");
        if (resource) {
            const fromSpain = geolocation?.country === 'ES';
            let price = userIsPRO ? resourcePROPrice ?? 0 : resourcePrice ?? 0;

            if (discount) {
                price = discount.new_price;
            }

            let actualPaymentMethod = fromSpain ? paymentMethod ?? '' : 'Stripe';
            if (price === 0) {
                actualPaymentMethod = 'Free';
            }

            appDispatch(
                postPaymentRequestThunk({
                    data: {
                        resource_id: resource.id,
                        user_id: user?.id ?? '',
                        pro_code: user?.pro_code ?? '',
                        discount_id: discount?.id ?? 0,
                        payment_method: actualPaymentMethod,
                        price: price
                    },
                    type: feature as string
                })
            );
        }
    }, [resource]);

    useEffect(() => {
        console.log("GEOLOCATION");
        if (geolocation) {
            switch (feature) {
                case Features.SCIENTIFIC_TEXT:
                    setResource(userInput);
                    break;
                case Features.QUESTION_ANSWER:
                    setResource(questionAnswer);
                    break;
                case Features.DEFENSE:
                    setResource(defense);
                    break;
                case Features.TRANSLATION:
                    setResource(translation);
                    break;
                default:
                    setResource(userInput);
                    break;
            }
        }
    }, [geolocation]);

    useEffect(() => {
        console.log("[]");
        // dispatch(loaderActions.toggleLoading());
        if (!geolocation) {
            appDispatch(getGeolocationThunk({}));
        }
    }, []);

    return {};
};

export default PaymentController;
