import styles from './Home.module.scss';
import doctor from '@/assets/images/doctor.png';
import ScientificTextTutorial from '@/assets/images/scientific_text_tutorial_horizontal_2.png';
import ScientificTextTutorialVertical from '@/assets/images/scientific_text_tutorial_vertical.png';
import QuestionsAnswersTutorial from '@/assets/images/question_answer_tutorial_horizontal_2.png';
import QuestionsAnswersTutorialVertical from '@/assets/images/question_answer_tutorial_vertical.png';
import DefenseTutorial from '@/assets/images/defense_tutorial_horizontal_2.png';
import DefenseTutorialVertical from '@/assets/images/defense_tutorial_vertical.png';
import TranslationTutorial from '@/assets/images/translation_tutorial_horizontal_2.png';
import TranslationTutorialVertical from '@/assets/images/translation_tutorial_vertical.png';
import CheckIcon from '@/assets/icons/check.svg';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Features } from '@/utils/constants/features';
import ReactHtmlParser from 'react-html-parser';
import { useState } from 'react';

const TITLE = "SANAI,<br/>¡tu ayudante científico inteligente!";
const INTRODUCTION = `
<ul>
<li>La mejor ayuda para <b>redactar tu trabajo científico</b> de calidad y original.</li>
<li><b>Responde cualquier pregunta/examen</b> tipo test (texto o foto).</li>
<li><b>Impugna preguntas tipo test</b> (de oposiciones, examenes,...) justificando “tu respuesta correcta” con bibliografía científica.</li>
<li><b>Traduce tu texto científico</b> como un experto nativo (cualquier idioma).</li>
</ul>
`;
const DESCRIPTION = "La plataforma Nº1 de asesoramiento y apoyo de inteligencia artificial para facilitar y ayudar en la publicación de trabajos científicos de calidad a los sanitarios y en diferentes áreas formativas.";
const BULLET_POINTS = [
    "Adaptación a <b>estándares científicos oficiales</b>.",
    "<b>Referencias bibliográficas</b> detalladas.",
    "<b>Integración de múltiples fuentes</b> y proveedores de IA.",
    "<b>Extensión de la redacción modificable</b>.",
    "<b>Raṕido</b> (resultado en menos de 1 hora).",
    "Se recibe por <b>email (en Word y PDF)</b>."
];
const BUTTON_SCIENTIFIC_TEXT = "Redactar Trabajo Científico";
const BUTTON_QUESTIONS_ANSWERS = "Responder Tipo Test";
const BUTTON_DEFENSE = "Impugnar Preguntas Tipo Test";
const BUTTON_TRANSLATIONS_TEXT = "Traducir Texto Científico";

// Common components
const Image = ({ src, alt }: { src: string, alt: string }) => (
    <div className={styles.image}>
        <img src={src} alt={alt} />
    </div>
);

const Title = ({ title }: { title: string }) => (
    <div className={styles.title}>
        <h1>{ReactHtmlParser(title)}</h1>
    </div>
);

const Introduction = ({ introduction }: { introduction: string }) => (
    <div className={styles.introduction}>
        {ReactHtmlParser(introduction)}
    </div>
);

const BulletPoints = ({ bulletPoints }: { bulletPoints: string[] }) => (
    <div className={styles.bulletPoints}>
        <ul>
            {bulletPoints.map((bulletPoint, index) => (
                <li key={index}>
                    <CheckIcon />{ReactHtmlParser(`<span>${bulletPoint}</span>`)}
                </li>
            ))}
        </ul>
    </div>
);

const Buttons = ({ navigate }: { navigate: (path: string) => void }) => (
    <div className={styles.buttons}>
        <div className={styles.button}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${Features.SCIENTIFIC_TEXT}`)}>
                {BUTTON_SCIENTIFIC_TEXT}
            </Button>
        </div>

        <div className={styles.button}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${Features.QUESTION_ANSWER}`)}>
                {BUTTON_QUESTIONS_ANSWERS}
            </Button>
        </div>

        <div className={styles.button}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${Features.DEFENSE}`)}>
                {BUTTON_DEFENSE}
            </Button>
        </div>

        <div className={styles.button}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={() => navigate(`/${Features.TRANSLATION}`)}>
                {BUTTON_TRANSLATIONS_TEXT}
            </Button>
        </div>
    </div>
);

const TutorialButtons = ({ onClick }: { onClick: (feature: Features) => void }) => (
    <div className={styles.buttons}>
        <div className={styles.tutorialButton}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.SMALL} onClick={() => onClick(Features.SCIENTIFIC_TEXT)}>
                ¿Cómo funciona?
            </Button>
        </div>

        <div className={styles.tutorialButton}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.SMALL} onClick={() => onClick(Features.QUESTION_ANSWER)}>
                ¿Cómo funciona?
            </Button>
        </div>

        <div className={styles.tutorialButton}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.SMALL} onClick={() => onClick(Features.DEFENSE)}>
                ¿Cómo funciona?
            </Button>
        </div>

        <div className={styles.tutorialButton}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.SMALL} onClick={() => onClick(Features.TRANSLATION)}>
                ¿Cómo funciona?
            </Button>
        </div>
    </div>
);

const ButtonRow = ({ buttonText, withTutorial = true, onClickFeature, onClickTutorial }: { buttonText: string, withTutorial?: boolean, onClickFeature: () => void, onClickTutorial: () => void }) => (
    <div className={styles.buttonBlock}>
        <div className={styles.featureButton}>
            <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.MEDIUM} onClick={onClickFeature}>
                {buttonText}
            </Button>
        </div>
        {withTutorial &&
            <div className={styles.tutorialButton}>
                <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.SMALL} onClick={onClickTutorial}>
                    ¿Cómo funciona?
                </Button>
            </div>
        }
    </div>
);

const TutorialImage = ({ src, isShown }: { src: string, isShown: boolean }) => (
    <div className={`${styles.imageContainer} ${isShown ? styles.loaded : ''}`}>
        <img
            className={`${styles.tutorialImage} ${isShown ? styles.loaded : ''}`}
            src={src}
            alt="tutorial"
        />
    </div>
);

const Home = () => {
    const navigate = useNavigate();

    const isTabletLandscape = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const isTabletPortrait = useMediaQuery({ minWidth: 481, maxWidth: 767 });
    const isMobile = useMediaQuery({ maxWidth: 480 });

    const [isTutorialShown, setIsTutorialShown] = useState<boolean>(false);
    const [selectedTutorial, setSelectedTutorial] = useState<string>(Features.SCIENTIFIC_TEXT);

    const getFlexDirection = () => {
        if (isMobile || isTabletPortrait || isTabletLandscape) {
            return 'flex-column';
        }

        return 'flex-row';
    };

    const getTutorialImage = () => {
        switch (selectedTutorial) {
            case Features.SCIENTIFIC_TEXT:
                return (isMobile || isTabletPortrait) ? ScientificTextTutorialVertical : ScientificTextTutorial;
            case Features.QUESTION_ANSWER:
                return (isMobile || isTabletPortrait) ? QuestionsAnswersTutorialVertical : QuestionsAnswersTutorial;
            case Features.DEFENSE:
                return (isMobile || isTabletPortrait) ? DefenseTutorialVertical : DefenseTutorial;
            case Features.TRANSLATION:
                return (isMobile || isTabletPortrait) ? TranslationTutorialVertical : TranslationTutorial;
        }

        return (isMobile || isTabletPortrait) ? ScientificTextTutorialVertical : ScientificTextTutorial;;
    };

    const tutorialOnClick = (tutorial: string) => {
        if (tutorial === selectedTutorial && isTutorialShown) {
            setIsTutorialShown(false);
            return;
        }

        setSelectedTutorial(tutorial);
        setIsTutorialShown(true);
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.content} ${getFlexDirection()}`}>

                {(isTabletPortrait || isTabletLandscape) ? (
                    <>
                        <div className={styles.imageTitleRow}>
                            <Image src={doctor} alt="SANAI" />
                            <Title title={TITLE} />
                        </div>

                        <div className={styles.text}>
                            <Introduction introduction={INTRODUCTION} />
                            <BulletPoints bulletPoints={BULLET_POINTS} />
                            {isTabletPortrait ? (
                                <div className={styles.buttonsBlock}>
                                    <ButtonRow
                                        buttonText={BUTTON_SCIENTIFIC_TEXT}
                                        onClickFeature={() => navigate(`/${Features.SCIENTIFIC_TEXT}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.SCIENTIFIC_TEXT)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.SCIENTIFIC_TEXT} />
                                    <ButtonRow
                                        buttonText={BUTTON_QUESTIONS_ANSWERS}
                                        onClickFeature={() => navigate(`/${Features.QUESTION_ANSWER}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.QUESTION_ANSWER)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.QUESTION_ANSWER} />
                                    <ButtonRow
                                        buttonText={BUTTON_DEFENSE}
                                        onClickFeature={() => navigate(`/${Features.DEFENSE}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.DEFENSE)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.DEFENSE} />
                                    <ButtonRow
                                        buttonText={BUTTON_TRANSLATIONS_TEXT}
                                        onClickFeature={() => navigate(`/${Features.TRANSLATION}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.TRANSLATION)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.TRANSLATION} />
                                </div>
                            ) : (
                                <div className={styles.buttonsBlock}>
                                    <Buttons navigate={navigate} />
                                    <TutorialButtons onClick={tutorialOnClick} />
                                </div>
                            )}

                        </div>
                    </>
                ) : (
                    <>
                        <Image src={doctor} alt="SANAI" />

                        <div className={styles.text}>
                            <Title title={TITLE} />
                            <Introduction introduction={INTRODUCTION} />
                            <BulletPoints bulletPoints={BULLET_POINTS} />
                            {isMobile ? (
                                <div className={styles.buttonsBlock}>
                                    <ButtonRow
                                        buttonText={BUTTON_SCIENTIFIC_TEXT}
                                        onClickFeature={() => navigate(`/${Features.SCIENTIFIC_TEXT}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.SCIENTIFIC_TEXT)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.SCIENTIFIC_TEXT} />
                                    <ButtonRow
                                        buttonText={BUTTON_QUESTIONS_ANSWERS}
                                        onClickFeature={() => navigate(`/${Features.QUESTION_ANSWER}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.QUESTION_ANSWER)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.QUESTION_ANSWER} />
                                    <ButtonRow
                                        buttonText={BUTTON_DEFENSE}
                                        onClickFeature={() => navigate(`/${Features.DEFENSE}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.DEFENSE)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.DEFENSE} />
                                    <ButtonRow
                                        buttonText={BUTTON_TRANSLATIONS_TEXT}
                                        onClickFeature={() => navigate(`/${Features.TRANSLATION}`)}
                                        onClickTutorial={() => tutorialOnClick(Features.TRANSLATION)}
                                    />
                                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown && selectedTutorial === Features.TRANSLATION} />
                                </div>
                            ) : (
                                <div className={styles.buttonsBlock}>
                                    <div>
                                        <Buttons navigate={navigate} />
                                    </div>
                                    <div>
                                        <TutorialButtons onClick={tutorialOnClick} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            {!(isTabletPortrait || isMobile) && (
                <div className={styles.tutorialBlock}>
                    <TutorialImage src={getTutorialImage()} isShown={isTutorialShown} />
                </div>
            )}

            <div className={styles.description}>
                {DESCRIPTION}
            </div>
        </div >
    );
};

export default Home;