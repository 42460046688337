import { Payment, PaymentRequest } from '@/models/payment';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem
} from '@/utils/helpers/localStorage.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentState {
    isLoading: boolean;
    error: Error | undefined;
    paymentMethod?: string;
    payment?: Payment;
}

export const initialState: PaymentState = {
    isLoading: false,
    error: undefined,
    paymentMethod: 'Visa',
    payment: undefined
};

export const postPaymentRequestThunk = createAsyncThunk<
    Payment,
    { data: PaymentRequest, type: string },
    { rejectValue: Error }
>('Payment', async (params, thunkApi) => {
    return request({
        url: `/api/purchases/${params.type}/payment`,
        method: 'POST',
        data: params.data
    })
        .then((res: ApiResponse<Payment>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            // Eliminamos del local storage tanto el id del user_input/question_answer/defense, como el user completo
            const userInput = getLocalStorageItem('userInput');
            if (userInput) {
                delete userInput.id, delete userInput.user_id, delete userInput.status;
                setLocalStorageItem('userInput', userInput);
            }
            const questionAnswer = getLocalStorageItem('questionAnswer');
            if (questionAnswer) {
                delete questionAnswer.id, delete questionAnswer.user_id, delete questionAnswer.status;
                setLocalStorageItem('questionAnswer', questionAnswer);
            }
            const defense = getLocalStorageItem('defense');
            if (defense) {
                delete defense.id, delete defense.user_id, delete defense.status;
                setLocalStorageItem('defense', defense);
            }
            removeLocalStorageItem('user');

            return thunkApi.rejectWithValue(err.data);
        });
});

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialState,
    reducers: {
        setPayment: (state, action: PayloadAction<Payment>) => {
            state.payment = action.payload;
        },
        resetPayment: (state) => {
            state = initialState;
        },
        setPaymentMethod: (state, action: PayloadAction<string>) => {
            state.paymentMethod = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postPaymentRequestThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = undefined), (state.payment = payload);
        });
    }
});

export const { setPayment, resetPayment, setPaymentMethod } = paymentSlice.actions;
