import { Defense } from '@/models/defense';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { removeLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DefenseState {
    isLoading: boolean;
    error: Error | undefined;
    defense: Defense;
}

export const initialState: DefenseState = {
    isLoading: false,
    error: undefined,
    defense: {
        id: undefined,
        user_id: undefined,
        question: undefined,
        possible_answers: undefined,
        teacher_answer_id: undefined,
        student_answer_id: undefined,
        defense: undefined,
        created_at: undefined,
        updated_at: undefined
    }
};

export const postDefenseThunk = createAsyncThunk<
    Defense,
    { defense: Defense },
    { rejectValue: Error }
>('Defense', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/defenses`,
        method: 'POST',
        data: params.defense
    })
        .then((res: ApiResponse<Defense>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchDefenseThunk = createAsyncThunk<
    Defense,
    { defense: Defense },
    { rejectValue: Error }
>('PatchDefense', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/defenses/${params.defense.id}`,
        method: 'PATCH',
        data: params.defense
    })
        .then((res: ApiResponse<Defense>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const defenseSlice = createSlice({
    name: 'defense',
    initialState: initialState,
    reducers: {
        setDefense: (state, action: PayloadAction<Defense>) => {
            state.defense = action.payload;
        },
        resetDefense: (state) => {
            state.defense = { ...initialState.defense };
            removeLocalStorageItem('defense');
        },
        updateField: (state, action: PayloadAction<{ field: string; newValue: any }>) => {
            const { field, newValue } = action.payload;
            // console.log('updateField', field, newValue);

            // update the field
            state.defense[field] = newValue;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postDefenseThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false),
                    (state.error = undefined),
                    (state.defense = payload);
                // setLocalStorageItem('defense', payload);
            })
            .addCase(patchDefenseThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false),
                    (state.error = undefined),
                    (state.defense = payload);
            });
    }
});

export const { setDefense, resetDefense, updateField } = defenseSlice.actions;
