import Button from '@/components/Button/Button';
import styles from './Error.module.scss';
import sadDoctor from '@/assets/images/sad_doctor.png';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetUser } from '@/store/user/user.slice';


const Error = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetUser());
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.image}>
                    <img src={sadDoctor} />
                </div>
                <div className={styles.text}>
                    <h1>
                        Ups...
                        <br />
                        Parece que algo ha fallado
                    </h1>
                </div>
                <div className={styles.button}>
                    <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.LARGE} onClick={() => navigate('/')}>
                        Volver al inicio
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Error;
