import { TextType, StudyType } from "@/models/user_input";

export interface WordsLimits {
    min: number;
    max: number;
};

export enum Sections {
    INTRODUCTION = "introduction",
    OBJECTIVES = "objectives",
    METHODOLOGY = "methodology",
    RESULTS = "results",
    DISCUSSION = "discussion",
    BIBLIOGRAPHY = "bibliography",
    CLINICAL_CASE = "clinical_case",
    TEXT = "text",
};

export type StudySections = {
    [x: string]: WordsLimits;
}

export const WORDS_LIMIT = {
    [TextType.CONGRESS_POSTER]: {
        [StudyType.REVIEW]: {
            [Sections.INTRODUCTION]: {
                min: 200,
                max: 320,
            } as WordsLimits,
            [Sections.METHODOLOGY]: {
                min: 200,
                max: 680,
            } as WordsLimits,
            [Sections.RESULTS]: {
                min: 700,
                max: 1300,
            } as WordsLimits,
            [Sections.DISCUSSION]: {
                min: 400,
                max: 700,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: {
                min: 0,
                max: 700,
            } as WordsLimits,
        },
        [StudyType.RESEARCH]: {
            [Sections.INTRODUCTION]: {
                min: 1300,
                max: 2000,
            } as WordsLimits,
            [Sections.METHODOLOGY]: {
                min: 300,
                max: 1000,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: {
                min: 0,
                max: 700,
            } as WordsLimits,
        },
        [StudyType.CLINICAL_CASE]: {
            [Sections.INTRODUCTION]: {
                min: 200,
                max: 420,
            } as WordsLimits,
            [Sections.CLINICAL_CASE]: {
                min: 700,
                max: 1500,
            } as WordsLimits,
            [Sections.DISCUSSION]: {
                min: 500,
                max: 800,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: {
                min: 0,
                max: 700,
            } as WordsLimits,
        },
        [StudyType.CARE_PLAN]: {
            [Sections.TEXT]: {
                min: 200,
                max: 320,
            } as WordsLimits
        }
    },
    [TextType.BOOK_CHAPTER]: {
        [StudyType.REVIEW]: {
            [Sections.INTRODUCTION]: {
                min: 200,
                max: 1100,
            } as WordsLimits,
            objectives: {
                min: 20,
                max: 500,
            } as WordsLimits,
            [Sections.METHODOLOGY]: {
                min: 40,
                max: 1200,
            } as WordsLimits,
            [Sections.RESULTS]: {
                min: 280,
                max: 2500,
            } as WordsLimits,
            [Sections.DISCUSSION]: {
                min: 100,
                max: 1100,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: null, // No limit
        },
        [StudyType.RESEARCH]: {
            [Sections.INTRODUCTION]: {
                min: 200,
                max: 1100,
            } as WordsLimits,
            objectives: {
                min: 20,
                max: 500,
            } as WordsLimits,
            [Sections.METHODOLOGY]: {
                min: 40,
                max: 1200,
            } as WordsLimits,
            [Sections.RESULTS]: {
                min: 280,
                max: 2500,
            } as WordsLimits,
            [Sections.DISCUSSION]: {
                min: 100,
                max: 1100,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: null, // No limit
        },
        [StudyType.CLINICAL_CASE]: {
            [Sections.INTRODUCTION]: {
                min: 200,
                max: 1100,
            } as WordsLimits,
            objectives: {
                min: 20,
                max: 500,
            } as WordsLimits,
            [Sections.METHODOLOGY]: {
                min: 40,
                max: 1200,
            } as WordsLimits,
            [Sections.RESULTS]: {
                min: 280,
                max: 2500,
            } as WordsLimits,
            [Sections.DISCUSSION]: {
                min: 100,
                max: 1100,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: null, // No limit
        },
        [StudyType.CARE_PLAN]: {
            [Sections.INTRODUCTION]: {
                min: 200,
                max: 1100,
            } as WordsLimits,
            objectives: {
                min: 20,
                max: 500,
            } as WordsLimits,
            [Sections.METHODOLOGY]: {
                min: 40,
                max: 1200,
            } as WordsLimits,
            [Sections.RESULTS]: {
                min: 280,
                max: 2500,
            } as WordsLimits,
            [Sections.DISCUSSION]: {
                min: 100,
                max: 1100,
            } as WordsLimits,
            [Sections.BIBLIOGRAPHY]: null, // No limit
        },
    }
};

