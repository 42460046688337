import { useDispatch, useSelector } from 'react-redux';
import { checkUserIsPROThunk, postUserThunk, setUser, updateField } from '@/store/user/user.slice';
import { selectUser, selectUserIsPRO } from '@/store/user/user.selectors';
import { selectTermsAccepted } from '@/store/formProgress/formProgress.selectors';
import { setTermsAccepted } from '@/store/formProgress/formProgress.slice';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { patchUserInputThunk } from '@/store/userInput/userInput.slice';
import { useAppDispatch } from '@/index';
import { selectPaymentMethod } from '@/store/payment/payment.selectors';
import { setPaymentMethod } from '@/store/payment/payment.slice';
import { UserInput } from '@/models/user_input';
import { User } from '@/models/user';
import { selectGeolocation } from '@/store/geolocation/geolocation.selectors';
import { getGeolocationThunk } from '@/store/geolocation/geolocation.slice';
import { checkDiscountCodeThunk, setCode } from '@/store/discount/discount.slice';
import { selectCode, selectDiscount } from '@/store/discount/discount.selectors';
import { Features } from '@/utils/constants/features';
import { selectQuestionAnswer } from '@/store/questionAnswer/questionAnswer.selectors';
import { selectDefense } from '@/store/defense/defense.selectors';
import { selectTranslation } from '@/store/translation/translation.selectors';
import { patchQuestionAnswerThunk } from '@/store/questionAnswer/questionAnswer.slice';
import { QuestionAnswer } from '@/models/question_answer';
import { patchDefenseThunk } from '@/store/defense/defense.slice';
import { Defense } from '@/models/defense';
import { patchTranslationThunk } from '@/store/translation/translation.slice';
import { Translation } from '@/models/translation';
import { selectUserInput } from '@/store/userInput/userInput.selectors';
import { selectResourcePROPrice, selectResourcePrice } from '@/store/price/resourcePrice.selectors';
import { getResourcePriceThunk } from '@/store/price/resourcePrice.slice';

const CheckoutController = (feature: Features) => {
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();

    const code = useSelector(selectCode);
    const user = useSelector(selectUser);
    const discount = useSelector(selectDiscount);
    const userIsPRO = useSelector(selectUserIsPRO);
    const userInput = useSelector(selectUserInput);
    const questionAnswer = useSelector(selectQuestionAnswer);
    const defense = useSelector(selectDefense);
    const geolocation = useSelector(selectGeolocation);
    const resourcePrice = useSelector(selectResourcePrice);
    const resourcePROPrice = useSelector(selectResourcePROPrice);
    const termsAccepted = useSelector(selectTermsAccepted);
    const paymentMethod = useSelector(selectPaymentMethod);
    const translation = useSelector(selectTranslation);

    const isSanaiPRO = window.location.hostname.includes('pro.');

    const [price, setPrice] = useState<number | undefined>(undefined);
    const [fromSpain, setFromSpain] = useState<boolean>(false);
    const [resource, setResource] = useState<any>(undefined);

    const userRef = useRef(user);

    const updateUser = async () => {
        let updatedUser: User = { ...userRef.current };
        let user_id = updatedUser?.id;
        if (!user_id) {
            const res: any = await appDispatch(postUserThunk({ user: updatedUser }));
            if (res.error) {
                return;
            }

            const pro_code = updatedUser.pro_code;
            updatedUser = { ...res.payload, pro_code: pro_code };
            user_id = updatedUser?.id;
            dispatch(setUser(updatedUser));
        }

        // Si el usuario se ha registrado correctamente, actualizamos el recurso en el backend
        let resPatch: any = null;
        switch (feature) {
            case Features.SCIENTIFIC_TEXT:
                resPatch = await appDispatch(
                    patchUserInputThunk({
                        userInput: { ...userInput, user_id: user_id ?? '' } as UserInput
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
            case Features.QUESTION_ANSWER:
                resPatch = await appDispatch(
                    patchQuestionAnswerThunk({
                        questionAnswer: {
                            ...questionAnswer,
                            user_id: user_id ?? ''
                        } as QuestionAnswer
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
            case Features.DEFENSE:
                resPatch = await appDispatch(
                    patchDefenseThunk({
                        defense: { ...defense, user_id: user_id ?? '' } as Defense
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
            case Features.TRANSLATION:
                resPatch = await appDispatch(
                    patchTranslationThunk({
                        translation: { ...translation, user_id: user_id ?? '' } as Translation
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
        }

        return updatedUser as User;
    };

    const handleInputChange = (e: any, field: string) => {
        dispatch(updateField({ field: field, newValue: e }));
    };

    const handleTermsAcceptedChange = (newValue: string | number | boolean) => {
        dispatch(setTermsAccepted(newValue as boolean));
    };

    const handlePaymentMethodChange = (newValue: string) => {
        dispatch(setPaymentMethod(newValue));
    };

    const handleDiscountCodeChange = (newValue: string | number | boolean) => {
        dispatch(setCode(newValue as string));
    };

    useEffect(() => {
        if (discount && price) {
            // Se aplica el descuento al precio del pedido
            setPrice(discount.new_price);
        }
    }, [discount]);

    useEffect(() => {
        if (code) {
            // Se envía el código al backend para que se actualice el precio del pedido
            appDispatch(checkDiscountCodeThunk({ code: code, type: feature }));
        }
    }, [code]);

    useEffect(() => {
        if (!userIsPRO) {
            setPrice(resourcePrice);
        }
    }, [resourcePrice]);

    useEffect(() => {
        if (isSanaiPRO && userIsPRO) {
            setPrice(resourcePROPrice);
        } else {
            setPrice(resourcePrice);
        }
    }, [userIsPRO]);

    useEffect(() => {
        if (
            userRef.current.pro_code !== user?.pro_code &&
            isSanaiPRO &&
            user?.pro_code &&
            user?.email
        ) {
            // Se envía el código al backend para que se actualice el precio del pedido
            appDispatch(checkUserIsPROThunk({ email: user.email, pro_code: user.pro_code }));
        }
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        if (geolocation) {
            setFromSpain(geolocation.country === 'ES');
        }
    }, [geolocation]);

    useEffect(() => {
        if (resource) {
            if (!resource.id) {
                navigate(`/${feature}`);
            }

            // Pedimos el precio al backend
            appDispatch(getResourcePriceThunk({ resourceId: resource?.id, type: feature }));
        }
    }, [resource]);

    useEffect(() => {
        if (!geolocation) {
            appDispatch(getGeolocationThunk({}));
        }

        switch (feature) {
            case Features.SCIENTIFIC_TEXT:
                setResource(userInput);
                break;
            case Features.QUESTION_ANSWER:
                setResource(questionAnswer);
                break;
            case Features.DEFENSE:
                setResource(defense);
                break;
            case Features.TRANSLATION:
                setResource(translation);
                break;
            default:
                break;
        }
    }, []);

    return {
        user,
        resource,
        termsAccepted,
        paymentMethod,
        isSanaiPRO,
        userIsPRO,
        price,
        fromSpain,
        code,
        discount,
        updateUser,
        handleInputChange,
        handleTermsAcceptedChange,
        handlePaymentMethodChange,
        handleDiscountCodeChange
    };
};

export default CheckoutController;
