import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useSelector } from 'react-redux';
import { selectCurrentStep } from '@/store/formProgress/formProgress.selectors';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import CustomStepChecked from '@/assets/icons/stepChecked.svg';
import CustomStepActive from '@/assets/icons/stepActive.svg';
import CustomStep from '@/assets/icons/step.svg';


const CustomConnector = styled(StepConnector)(({/* theme */ }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'var(--color-primary)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'var(--color-primary)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'var(--color-secondary)',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({/* theme, ownerState */ }) => ({
        color: 'var(--color-secondary)',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        // ...(ownerState.active && {
        //     color: 'var(--color-primary)',
        // }),
        // '& .CustomStepIcon-completedIcon': {
        //     color: 'var(--color-primary)',
        //     zIndex: 1,
        //     fontSize: 18,
        // },
        // '& .CustomStepIcon-circle': {
        //     width: 8,
        //     height: 8,
        //     borderRadius: '50%',
        //     backgroundColor: 'currentColor',
        // },
    }),
);

function CustomStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <CustomStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <CustomStepChecked />
            ) : (
                active ? <CustomStepActive /> : <CustomStep />
            )}
        </CustomStepIconRoot>
    );
}

const CustomStepper = ({ steps }: { steps: number }) => {
    const currentStep = useSelector(selectCurrentStep);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={currentStep - 1} alternativeLabel connector={<CustomConnector />}>
                {[...Array(steps)].map((_, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

export default CustomStepper;
