import { Translation } from '@/models/translation';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { removeLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TranslationState {
    isLoading: boolean;
    error: Error | undefined;
    translation: Translation;
}

export const initialState: TranslationState = {
    isLoading: false,
    error: undefined,
    translation: {
        id: undefined,
        user_id: undefined,
        data: undefined,
        original_text: undefined,
        created_at: undefined,
        updated_at: undefined
    }
};

export const postTranslationThunk = createAsyncThunk<
    Translation,
    { translation: Translation },
    { rejectValue: Error }
>('Translation', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/translations`,
        method: 'POST',
        data: params.translation
    })
        .then((res: ApiResponse<Translation>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchTranslationThunk = createAsyncThunk<
    Translation,
    { translation: Translation },
    { rejectValue: Error }
>('PatchTranslation', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/translations/${params.translation.id}`,
        method: 'PATCH',
        data: params.translation
    })
        .then((res: ApiResponse<Translation>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const translationSlice = createSlice({
    name: 'translation',
    initialState: initialState,
    reducers: {
        setTranslation: (state, action: PayloadAction<Translation>) => {
            state.translation = action.payload;
        },
        resetTranslation: (state) => {
            state.translation = { ...initialState.translation };
            removeLocalStorageItem('translation');
        },
        updateTranslationOriginalText: (state, action: PayloadAction<string | undefined>) => {
            state.translation.original_text = action.payload;
        },
        updateTranslationTargetLanguage: (state, action: PayloadAction<string | undefined>) => {
            state.translation.target_language = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postTranslationThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false),
                    (state.error = undefined),
                    (state.translation = payload);
                setLocalStorageItem('translation', payload);
            })
            .addCase(patchTranslationThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false),
                    (state.error = undefined),
                    (state.translation = payload);
                setLocalStorageItem('translation', payload);
            });
    }
});

export const {
    setTranslation,
    resetTranslation,
    updateTranslationOriginalText,
    updateTranslationTargetLanguage,
} = translationSlice.actions;
