import { User } from '@/models/user';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { removeLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface UserState {
    isLoading: boolean;
    error: Error | undefined;
    user: User;
    isPRO: boolean;
}

export const initialState: UserState = {
    isLoading: false,
    error: undefined,
    user: {
        id: undefined,
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        password: undefined,
        pro_code: undefined,
        created_at: undefined,
        updated_at: undefined
    } as User,
    isPRO: false
};

export const postUserThunk = createAsyncThunk<User, { user: User }, { rejectValue: Error }>(
    'User',
    async (params, thunkApi) => {
        return request({
            url: `/api/users`,
            method: 'POST',
            data: params.user
        })
            .then((res: ApiResponse<User>) => {
                return res.data;
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error('Ups... Error al crear el usuario. Inténtalo de nuevo más tarde.');
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const checkUserIsPROThunk = createAsyncThunk<
    any,
    { email?: string; pro_code?: string },
    { rejectValue: Error }
>('User/checkIsPRO', async (params, thunkApi) => {
    await new Promise(resolve => setTimeout(resolve, 1000));

    return request({
        url: `/api/users/check_pro`,
        method: 'POST',
        data: params
    })
        .then((res: ApiResponse<User>) => {
            toast.success('Código PRO aplicado correctamente.');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) { //FIXME
                toast.error('Código PRO no válido.');
            } else {
                toast.error('Ups... Error al verificar si es PRO. Inténtalo de nuevo más tarde.');
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        resetUser: (state) => {
            state.user = {...initialState.user};
            state.isPRO = false;
            removeLocalStorageItem('user');
        },
        // update certain fields of the user input
        updateField: (state, action: PayloadAction<{ field: string; newValue: any }>) => {
            const { field, newValue } = action.payload;
            state.user[field] = newValue;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postUserThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false), (state.error = undefined), (state.user = {...payload, pro_code: state.user.pro_code});
                setLocalStorageItem('user', payload);
            })
            .addCase(checkUserIsPROThunk.fulfilled, (state) => {
                (state.isLoading = false), (state.error = undefined), (state.isPRO = true);
            })
            .addCase(checkUserIsPROThunk.rejected, (state, { error }) => {
                (state.isLoading = false), (state.error = error), (state.isPRO = false);
            });
    }
});

export const { setUser, resetUser, updateField } = userSlice.actions;
