import styles from "./Defense.module.scss";
import Input from '@/components/Input/Input';
import TextArea from '@/components/TextArea/TextArea';
import Button from '@/components/Button/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '@/store/defense/defense.slice';
import { selectDefense } from '@/store/defense/defense.selectors';

type Answer = {
    id: string;
    text: string;
    selectedByTeacher: boolean;
    selectedByStudent: boolean;
};

type DefensePayload = {
    email: string;
    question: {
        question: string;
        possible_answers: {
            id: string;
            value: string;
        }[];
        teacher_answer_id: string;
        student_answer_id: string;
    };
};

export default function Defense() {
    const dispatch = useDispatch();

    const defense = useSelector(selectDefense);

    let [answers, setAnswers] = useState<Answer[]>([
        { id: 'a)', text: '', selectedByTeacher: false, selectedByStudent: false },
        { id: 'b)', text: '', selectedByTeacher: false, selectedByStudent: false },
        { id: 'c)', text: '', selectedByTeacher: false, selectedByStudent: false },
        { id: 'd)', text: '', selectedByTeacher: false, selectedByStudent: false }
    ]);

    const [selectedByTeacher, setSelectedByTeacher] = useState<string>('a)');
    const [selectedByStudent, setSelectedByStudent] = useState<string>('b)');

    function addAnswer(answer: Answer) {
        setAnswers((answers) => [...answers, answer]);
    }

    function deleteAnswer(answer: Answer) {
        setAnswers((answers) => answers.filter((a) => a.id !== answer.id));
    }

    function updateAnswer(answer: Answer) {
        setAnswers((answers) => answers.map((a) => (a.id === answer.id ? answer : a)));
    }

    const handleInputChange = (field: string, newValue: any) => {
        dispatch(updateField({ field, newValue }));
    };

    useEffect(() => {
        handleInputChange('teacher_answer_id', selectedByTeacher);
        updateAnswer(
            answers.find((answer) => answer.id === selectedByTeacher) ?? {
                id: selectedByTeacher,
                text: '',
                selectedByTeacher: true,
                selectedByStudent: false
            }
        );
    }, [selectedByTeacher]);

    useEffect(() => {
        handleInputChange('student_answer_id', selectedByStudent);
        updateAnswer(
            answers.find((answer) => answer.id === selectedByStudent) ?? {
                id: selectedByStudent,
                text: '',
                selectedByTeacher: false,
                selectedByStudent: true
            }
        );
    }, [selectedByStudent]);

    useEffect(() => {
        if (answers) {
            console.log("ANSWERS", answers);
            handleInputChange('possible_answers', answers);
        }
    }, [answers]);

    return (
        <div className={styles.container}>
            <div className={styles.question}>
                <TextArea
                    placeholder="Introduce el enunciado de la pregunta"
                    required
                    type="text"
                    value={defense?.question}
                    onChange={(question: string) => handleInputChange('question', question)}
                />
            </div>

            <div className={styles.table}>
                <div key={answers[0].id} className={styles.row}>
                    <div className={styles.clearButton}></div>

                    <div className={styles.answerId}>
                        <Input
                            placeholder="(id)"
                            type="text"
                            value={answers[0].id}
                            onChange={(id) => updateAnswer({ ...answers[0], id })}
                        />
                    </div>

                    <div className={styles.answerText}>
                        <Input
                            placeholder="Respuesta oficial"
                            type="text"
                            value={answers[0].text}
                            onChange={(text) => updateAnswer({ ...answers[0], text })}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.text}>Introduce el resto de opciones y selecciona la que tú consideres correcta</div>

            <div className={styles.table}>
                {answers.slice(1).map((answer) => {
                    return (
                        <div key={answer.id} className={styles.row}>
                            <div className={styles.clearButton}>
                                <Button
                                    variant={ButtonVariants.SECONDARY}
                                    size={ButtonSizes.SMALL}
                                    onClick={() => deleteAnswer(answer)}
                                >
                                    <ClearIcon />
                                </Button>
                            </div>

                            <div className={styles.answerId}>
                                <Input
                                    placeholder="(id)"
                                    type="text"
                                    value={answer.id}
                                    onChange={(id) => updateAnswer({ ...answer, id })}
                                />
                            </div>

                            <div className={styles.answerText}>
                                <Input
                                    placeholder="Respuesta"
                                    type="text"
                                    value={answer.text}
                                    onChange={(text) => updateAnswer({ ...answer, text })}
                                />
                            </div>

                            <div className={styles.checkboxContainer}>
                                <Input
                                    type="checkbox"
                                    id="terms"
                                    checked={selectedByStudent === answer.id}
                                    onChange={() => setSelectedByStudent(selectedByStudent === answer.id ? '' : answer.id)}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className={styles.addButton}>
                <Button
                    variant={ButtonVariants.TERCIARY}
                    size={ButtonSizes.SMALL}
                    onClick={() => {
                        addAnswer({
                            id: '',
                            text: '',
                            selectedByTeacher: false,
                            selectedByStudent: false
                        });
                    }}
                >
                    <AddCircleIcon />
                    <span>Añadir respuesta</span>
                </Button>
            </div>
        </div>
    );
}