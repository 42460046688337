import { combineReducers } from 'redux';
import { translationSlice } from './translation.slice';

export const translationReducer = combineReducers({
    translation: translationSlice.reducer,
});

export const translationActions = {
    ...translationSlice.actions
};
