import { StylesConfig } from 'react-select';

const selectStyle: StylesConfig = {
    container: (base) => ({
        ...base,
        margin: '0',
        padding: '0',
        width: '100%',
        height: '100%',
    }),

    valueContainer: (base, props) => ({
        ...base,
        padding: '0',
        margin: '0',
        color: 'var(--color-text-secondary)'
    }),

    input: (base) => ({
        ...base,
        margin: '0',
        padding: '0',
        color: 'var(--color-text-secondary)'
    }),

    control: (base, state) => ({
        ...base,
        border: state.isFocused || state.hasValue
            ? '1px solid var(--color-primary)'
            : `1px solid var(--color-secondary)`,
        boxShadow: 'none',
        borderRadius: 'var(--border-radius-input)',
        padding: '23px 34px',
        fontSize: 'var(--font-content)',
        fontWeight: 'normal',
        // color: 'var(--color-text-secondary)',
        '&:hover': {
            borderColor: 'var(--color-primary)'
        }
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: 'var(--font-placeholder)',
        color: 'var(--color-placeholder)',
        margin: '0',
        padding: '0'
    }),

    singleValue: (provided) => ({
        ...provided,
        color: 'var(--color-text-secondary)'
    }),

    indicatorsContainer: (base) => ({
        ...base,
        padding: '0',
        margin: '0',
        height: 'var(--font-placeholder)'
    }),

    dropdownIndicator: (base, state) => ({
        ...base,
        color: 'var(--color-primary)',
        transition: 'all .3s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'
    }),

    indicatorSeparator: () => ({
        display: 'none'
    }),

    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'var(--color-secondary)' : 'transparent',
        color: state.isFocused ? 'var(--color-primary)' : 'var(--color-text)',
        padding: '23px 34px',
        '&:active': {
            background: 'var(--color-secondary)',
            color: 'var(--color-primary)'
        },
    }),

    menu: (provided) => ({
        ...provided,
        background: 'var(--bg-dropdown)',
        borderRadius: 'var(--border-radius-input)',
        boxShadow: 'var(--color-dropdown-gradient)',
        zIndex: 'var(--z-level-3)'
    })
};
export default selectStyle;
