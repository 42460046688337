import styles from "./Checkout.module.scss";
import PurchaseInfo from "@/components/PurchaseInfo/PurchaseInfo";
import useTranslator from "@/utils/hooks/useTranslator.hook";
import CheckoutController from "./Checkout.controller";
import { limitDecimalPlaces } from "@/utils/helpers/utils.helper";
import PersonalInfo from "../PersonalInfo/PersonalInfo";
import { useEffect, useState } from "react";
import Input from "@/components/Input/Input";
import { Features } from "@/utils/constants/features";

const Checkout = ({feature}: {feature: Features}) => {
    const { t } = useTranslator();

    const {
        user,
        resource,
        termsAccepted,
        paymentMethod,
        isSanaiPRO,
        userIsPRO,
        price,
        fromSpain,
        code,
        discount,
        updateUser,
        handleInputChange,
        handleTermsAcceptedChange,
        handlePaymentMethodChange,
        handleDiscountCodeChange
    } = CheckoutController(feature);

    //FIXME para forzar que PurchaseInfo se re-renderice
    const [key, setKey] = useState(Math.random());
    useEffect(() => {
        setKey(Math.random());
    }, [price, fromSpain]);
    //FIXME

    return (
        <div className={styles.content}>
            <PersonalInfo
                user={user}
                isSanaiPRO={isSanaiPRO}
                userIsPRO={userIsPRO}
                handleInputChange={handleInputChange}
            />
            {price !== undefined &&
                <>
                    <div className={styles.purchaseInfo} key={key}> {/* FIXME */}
                        <PurchaseInfo
                            description={t(feature)}
                            feature={feature}
                            cost={limitDecimalPlaces((price * 100 / 104), 2)}
                            taxes={limitDecimalPlaces((price * 4 / 104), 2)}
                            total={limitDecimalPlaces(price, 2)}
                            paymentMethod={paymentMethod}
                            resourceId={resource?.id}
                            updateUser={updateUser}
                            setPaymentMethod={handlePaymentMethodChange}
                            fromSpain={fromSpain}
                            // userId={user?.id}
                            discountId={discount?.id}
                        />
                    </div>
                    <div className={styles.discount}>
                        {/* <p style={{ width: "150%" }}>Código de descuento</p> */}
                        <Input
                            name="discount-code"
                            type="text"
                            placeholder={"Código de descuento"}
                            value={code}
                            onChange={handleDiscountCodeChange}
                            disabled={discount !== undefined}
                        />
                    </div>
                </>
            }
            <div className={styles.terms}>
                <div>
                    <Input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        value="terms"
                        checked={termsAccepted}
                        onChange={handleTermsAcceptedChange}
                    />
                </div>
                <div>
                    <label htmlFor="terms">He leído y acepto los <a href={process.env.AVISO_LEGAL_URL}>Términos y Condiciones</a></label>
                </div>
            </div>
        </div>
    );
};

export default Checkout;