import VisaMastercard from '@/assets/icons/visa-master.png';
import Bizum from '@/assets/icons/bizum.png';
import { FUNDING, PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate, useOutletContext } from "react-router-dom";
import request from "@/utils/helpers/apiResponse.helper";
import { CreateOrderActions } from '@paypal/paypal-js';
import { User } from '@/models/user';
import { Features } from '@/utils/constants/features';


export interface PurchaseInfoProps {
    id?: string;
    description: string;
    feature: Features;
    cost: number;
    taxes: number;
    total: number;
    paymentMethod?: string;
    resourceId?: string;
    updateUser: () => Promise<User | undefined>;
    setPaymentMethod: (newValue: string) => void;
    fromSpain: boolean;
    discountId?: number;
};

export const availablePaymentMethods = [
    'Visa',
    'Bizum',
    'PayPal',
];

const paypalOptions = {
    clientId: process.env.PAYPAL_CLIENT_ID ?? 'test',
    currency: 'EUR',
    intent: 'capture'
}

const PurchaseInfoController = ({  total, paymentMethod, feature, setPaymentMethod, updateUser, resourceId, discountId }: PurchaseInfoProps) => {
    const navigate = useNavigate();

    const [disabledNext] = useOutletContext() as [boolean];

    const getPaymentMethodIcon = (method: string) => {
        switch (method) {
            case 'Visa':
                return <img src={VisaMastercard} />;
            case 'PayPal':
                return <PayPalScriptProvider options={paypalOptions}>
                    <PayPalButtons
                        fundingSource={FUNDING.PAYPAL}
                        style={{ height: 44 }}
                        disabled={disabledNext || total === 0}
                        createOrder={(_, actions) => createOrder(actions)}
                        onApprove={(data, _) => onApprove(data)}
                        onError={() => navigate('/error')}
                    />
                </PayPalScriptProvider>;
            case 'Bizum':
                return <img src={Bizum} />;
        }
    };

    const handlePaymentMethodChange = (method: string) => {
        if (method !== paymentMethod) {
            setPaymentMethod(method);
        }
    };

    //FIXME //FIXME //FIXME REFACTORIZAR
    const createOrder = async (actions: CreateOrderActions) => {
        const user = await updateUser();
        if (!user) return '';

        // Activamos el loader
        // dispatch(loaderActions.toggleLoading());

        const res = await request({
            url: `/api/purchases/${feature}/payment`,
            method: 'POST',
            data: {
                resource_id: resourceId ?? '',
                user_id: user.id ?? '',
                pro_code: user.pro_code ?? '',
                payment_method: 'PayPal',
                price: total,
                discount_id: discountId ?? 0,
            }
        });

        // Desactivamos el loader
        // dispatch(loaderActions.toggleLoading());

        const req = {
            intent: 'capture',
            purchase_units: [
                {
                    amount: {
                        currency_code: 'EUR',
                        value: total,
                    },
                    custom_id: res.data['payment_order'],
                },
            ]
        };

        return actions.order.create(req);
    };

    const onApprove = async (data: any) => {
        const user = await updateUser();
        if (!user) return;

        await request({
            url: `/api/purchases/${feature}/${resourceId}/${user?.id}/Paypal`,
            method: 'POST',
            data: {
                payment_order: data.orderID,
                payment_method: 'PayPal',
                price: total,
            }
        })
            .finally(() => {
                navigate('/success');
            });
    };
    //FIXME //FIXME //FIXME REFACTORIZAR

    return { handlePaymentMethodChange, getPaymentMethodIcon }
}

export default PurchaseInfoController;