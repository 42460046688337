import { QuestionAnswer } from '@/models/question_answer';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { removeLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface QuestionAnswerState {
    isLoading: boolean;
    error: Error | undefined;
    questionAnswer: QuestionAnswer;
}

export const initialState: QuestionAnswerState = {
    isLoading: false,
    error: undefined,
    questionAnswer: {
        id: undefined,
        user_id: undefined,
        data: undefined,
        original_text: undefined,
        original_image: undefined,
        created_at: undefined,
        updated_at: undefined
    }
};

export const postQuestionAnswerThunk = createAsyncThunk<
    QuestionAnswer,
    { questionAnswer: QuestionAnswer },
    { rejectValue: Error }
>('QuestionAnswer', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/questions_answers`,
        method: 'POST',
        data: params.questionAnswer
    })
        .then((res: ApiResponse<QuestionAnswer>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchQuestionAnswerThunk = createAsyncThunk<
    QuestionAnswer,
    { questionAnswer: QuestionAnswer },
    { rejectValue: Error }
>('PatchQuestionAnswer', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/questions_answers/${params.questionAnswer.id}`,
        method: 'PATCH',
        data: params.questionAnswer
    })
        .then((res: ApiResponse<QuestionAnswer>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const questionAnswerSlice = createSlice({
    name: 'questionAnswer',
    initialState: initialState,
    reducers: {
        setQuestionAnswer: (state, action: PayloadAction<QuestionAnswer>) => {
            state.questionAnswer = action.payload;
        },
        resetQuestionAnswer: (state) => {
            state.questionAnswer = { ...initialState.questionAnswer };
            removeLocalStorageItem('questionAnswer');
        },
        updateQuestionAnswerOriginalText: (state, action: PayloadAction<string | undefined>) => {
            state.questionAnswer.original_text = action.payload;
        },
        updateQuestionAnswerOriginalImage: (state, action: PayloadAction<string | undefined>) => {
            state.questionAnswer.original_image = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postQuestionAnswerThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false),
                    (state.error = undefined),
                    (state.questionAnswer = payload);
                setLocalStorageItem('questionAnswer', payload);
            })
            .addCase(patchQuestionAnswerThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false),
                    (state.error = undefined),
                    (state.questionAnswer = payload);
                setLocalStorageItem('questionAnswer', payload);
            });
    }
});

export const {
    setQuestionAnswer,
    resetQuestionAnswer,
    updateQuestionAnswerOriginalText,
    updateQuestionAnswerOriginalImage
} = questionAnswerSlice.actions;
