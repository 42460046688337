import { patchDefenseThunk, postDefenseThunk } from './defense/defense.slice';
import { getGeolocationThunk } from './geolocation/geolocation.slice';
import { getResourcePriceThunk } from './price/resourcePrice.slice';
import { postQuestionAnswerThunk } from './questionAnswer/questionAnswer.slice';
import { patchTranslationThunk, postTranslationThunk } from './translation/translation.slice';
import { checkUserIsPROThunk, postUserThunk } from './user/user.slice';
import {
    patchUserInputThunk,
    postUserInputThunk
} from './userInput/userInput.slice';

const rootThunkData: any = [
    postUserInputThunk,
    patchUserInputThunk,
    postQuestionAnswerThunk,
    postQuestionAnswerThunk,
    postDefenseThunk,
    patchDefenseThunk,
    postTranslationThunk,
    patchTranslationThunk,
    postUserThunk,
    checkUserIsPROThunk,
    getResourcePriceThunk,
    getGeolocationThunk
];

const rootThunkHandler = (data: any[]) => {
    const newJ = {
        data: {}
    };

    data.map((e) => {
        newJ.data = {
            ...newJ.data,
            [e.pending as string]: (state: { isLoading: boolean }) => {
                state.isLoading = true;
            },
            [e.fulfilled as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            },
            [e.rejected as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            }
        };
    });

    return newJ.data;
};

export const rootThunk = rootThunkHandler(rootThunkData);
