import React from 'react';
import Select, { SelectProps } from '@/components/Select/Select';

type ReduxSelectProps = Omit<SelectProps, 'value' | 'onChange'> & {
    value?: string;
    onChange: (value: string) => void;
};

const ReduxSelect: React.FC<ReduxSelectProps> = ({ value, onChange, options, ...props }) => {
    // Encuentra el objeto option correspondiente al valor
    const selectedOption = options.find(opt => opt.value === value);

    return (
        <Select
            {...props}
            options={options}
            value={selectedOption}
            onChange={(option: any) => {
                onChange(option.value);
            }}
        />
    );
};

export default ReduxSelect;
