import styles from './FeatureLayout.module.scss';
import doctor from '@/assets/images/doctor.png';
import CustomStepper from '@/components/Stepper/Stepper';
import { Outlet } from 'react-router-dom';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import BackIcon from '@/assets/icons/back.svg';
import FeatureLayoutController from './FeatureLayout.controller';
import { Features } from '@/utils/constants/features';

const FeatureSteps: Record<Features, number> = {
    [Features.SCIENTIFIC_TEXT]: 3,
    [Features.QUESTION_ANSWER]: 2,
    [Features.DEFENSE]: 2,
    [Features.TRANSLATION]: 2,
    [Features.IMPROVE_TEXT]: 2,
};

const StepsTitles: Record<Features, string[]> = {
    [Features.SCIENTIFIC_TEXT]: ["¿Qué tipo de texto necesitas?", "Establece los límites de palabras", "Introduce tus datos personales"],
    [Features.QUESTION_ANSWER]: ["Introduce las preguntas y respuestas", "Introduce tus datos personales"],
    [Features.DEFENSE]: ["Impugnar pregunta de examen", "Introduce tus datos personales"],
    [Features.TRANSLATION]: ["Introduce el texto a traducir", "Introduce tus datos personales"],
    [Features.IMPROVE_TEXT]: ["Introduce el texto a mejorar", "Introduce tus datos personales"],
};

const FeatureLayout = ({ feature }: { feature: Features }) => {
    const { currentStep, disabledNext, handleBack, handleNext } = FeatureLayoutController(feature);

    return (
        <div className={styles.container}>
            <div className={`${styles.content} flex-column`}>
                <div className={styles.image}>
                    <img src={doctor} alt="SANAI" />
                </div>

                <div className={styles.stepper}>
                    <CustomStepper steps={FeatureSteps[feature]} />
                </div>

                <div className={styles.title}>
                    <h1>
                        {StepsTitles[feature][currentStep - 1]}
                    </h1>
                </div>

                <div className={styles.form}>
                    <Outlet context={[disabledNext]} />
                </div>

                <div className={styles.navigationButtons}>
                    <div className={styles.leftButton}>
                        {currentStep > 1 &&
                            <Button
                                variant={ButtonVariants.SECONDARY}
                                size={ButtonSizes.MEDIUM}
                                onClick={handleBack}
                                IconLeft={BackIcon}
                            >Atrás</Button>
                        }
                    </div>
                    <div className={styles.rightButton}>
                        {currentStep <= FeatureSteps[feature] &&
                            <Button
                                key={currentStep}
                                variant={ButtonVariants.PRIMARY}
                                size={ButtonSizes.MEDIUM}
                                onClick={handleNext}
                                disabled={disabledNext}
                            >{currentStep < FeatureSteps[feature] ? 'Continuar' : 'Procesar el pago'}</Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureLayout;
