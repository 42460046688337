import styles from "./TextInfo.module.scss";
import ReduxSelect from "@/components/Select/ReduxSelect";
import TextInfoController from "./TextInfo.controller";
import { TextType } from "@/models/user_input";
import useTranslator from "@/utils/hooks/useTranslator.hook";
import TextArea from "@/components/TextArea/TextArea";


const TextInfo = () => {
    const { t } = useTranslator();

    const { userInput, handleTextTypeChange, handleInputChange } = TextInfoController();

    return (
        <div className={styles.content}>
            <div className={styles.type}>
                <ReduxSelect
                    name="text-type"
                    placeholder="Elige el tipo de texto"
                    options={[
                        // { value: "scientific_article", label: TextType.SCIENTIFIC_ARTICLE },
                        { value: TextType.CONGRESS_POSTER, label: t(TextType.CONGRESS_POSTER) },
                        { value: TextType.BOOK_CHAPTER, label: t(TextType.BOOK_CHAPTER) },
                    ]}
                    value={userInput.text_type}
                    onChange={(newValue) => handleTextTypeChange(newValue)}
                />
            </div>
            <div className={styles.title}>
                <TextArea
                    name="text-title"
                    placeholder="Resume brevemente de qué debe tratar el texto científico"
                    value={userInput.title}
                    onChange={(newValue) => handleInputChange(newValue, "title")}
                />
            </div>
        </div>
    );
};

export default TextInfo;