import { Discount } from '@/models/discount';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface DiscountState {
    isLoading: boolean;
    error: Error | undefined;
    code: string;
    discount: Discount | undefined;
}

export const initialState: DiscountState = {
    isLoading: false,
    error: undefined,
    code: '',
    discount: undefined
};

export const checkDiscountCodeThunk = createAsyncThunk<
    Discount,
    { code: string, type: string },
    { rejectValue: Error }
>('checkDiscount', async (params, thunkApi) => {
    return request({
        url: `/api/discounts/check`,
        method: 'POST',
        data: params
    })
        .then((res: ApiResponse<Discount>) => {
            toast.success('¡Código de descuento aplicado!');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error('Código de descuento no válido.');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const discountSlice = createSlice({
    name: 'discount',
    initialState: initialState,
    reducers: {
        setCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        setDiscount: (state, action: PayloadAction<Discount>) => {
            state.discount = action.payload;
        },
        resetDiscount: (state) => {
            state.code = '';
            state.discount = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(checkDiscountCodeThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = undefined), (state.discount = payload);
        });
    }
});

export const { setCode, setDiscount, resetDiscount } = discountSlice.actions;
