import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANG } from '@/utils/constants/langs.constants';

import es from '@/assets/langs/es.json';
import en from '@/assets/langs/en.json';

i18n.use(initReactI18next).init({
    resources: {
        es,
        en
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
