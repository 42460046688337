import { UserInput } from '@/models/user_input';
import { StudySections } from '@/utils/constants/wordsLimits';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { removeLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import { limitDecimalPlaces } from '@/utils/helpers/utils.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserInputState {
    isLoading: boolean;
    error: Error | undefined;
    userInput: UserInput;
}

export const initialState: UserInputState = {
    isLoading: false,
    error: undefined,
    userInput: {
        id: undefined,
        user_id: undefined,
        text_type: undefined,
        title: undefined,
        overview: undefined,
        study_type: undefined,
        other_inputs: {
            words: {} as StudySections
        },
        status: undefined,
        created_at: undefined,
        updated_at: undefined
    },
};

export const postUserInputThunk = createAsyncThunk<
    UserInput,
    { userInput: UserInput },
    { rejectValue: Error }
>('UserInput', async (params, thunkApi) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return request({
        url: `/api/users_inputs`,
        method: 'POST',
        data: params.userInput
    })
        .then((res: ApiResponse<UserInput>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchUserInputThunk = createAsyncThunk<
    UserInput,
    { userInput: UserInput },
    { rejectValue: Error }
>('PatchUserInput', async (params, thunkApi) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return request({
        url: `/api/users_inputs/${params.userInput.id}`,
        method: 'PATCH',
        data: params.userInput
    })
        .then((res: ApiResponse<UserInput>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const userInputSlice = createSlice({
    name: 'userInput',
    initialState: initialState,
    reducers: {
        setUserInput: (state, action: PayloadAction<UserInput>) => {
            state.userInput = action.payload;
        },
        resetUserInput: (state) => {
            state.userInput = {...initialState.userInput};
            removeLocalStorageItem('userInput');
        },
        // update certain fields of the user input
        updateField: (state, action: PayloadAction<{ field: string; newValue: any }>) => {
            const { field, newValue } = action.payload;
            // console.log('updateField', field, newValue);

            // update the field
            // take care of the nested fields, like other_inputs.words.introduction.min
            const fieldParts = field.split('.');
            if (fieldParts.length === 1) {
                state.userInput[field] = newValue;
            } else {
                // This case can only be for other_inputs.words.<section>.<min|max>
                const currentState = { ...state.userInput };

                const section = fieldParts[2];
                const minOrMax = fieldParts[3];

                if (!currentState.other_inputs.words[section]) {
                    currentState.other_inputs.words[section] = {};
                }
                currentState.other_inputs.words[section][minOrMax] = newValue;

                state.userInput = currentState;
            }
        },
        resetWordsLimits: (state) => {
            state.userInput.other_inputs.words = {} as StudySections;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postUserInputThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false), (state.error = undefined), (state.userInput = payload);
                setLocalStorageItem('userInput', payload);
            })
            .addCase(patchUserInputThunk.fulfilled, (state, { payload }) => {
                (state.isLoading = false), (state.error = undefined), (state.userInput = payload);
            });
    }
});

export const { setUserInput, resetUserInput, updateField, resetWordsLimits } = userInputSlice.actions;
