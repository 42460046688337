import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import request from "@/utils/helpers/apiResponse.helper";


const Purchase = () => {
    const navigate = useNavigate();

    const { feature, resource_id, user_id, price, payment_order, payment_type } = useParams();

    useEffect(() => {
        request({
            url: `/api/purchases/${feature}/${resource_id}/${user_id}/${payment_type}`,
            method: 'POST',
            data: {
                payment_order: payment_order,
                payment_method: payment_type,
                price: price,
            }
        })
            .catch((error) => {
                console.log(error);
                navigate('/error');
            })
            .finally(() => {
                navigate('/success');
            });
    }, []);

    return (
        <div>

        </div>
    );
}

export default Purchase;
