import { ResourcePrice } from '@/models/price';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { limitDecimalPlaces } from '@/utils/helpers/utils.helper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ResourceResourcePriceState {
    isLoading: boolean;
    error: Error | undefined;
    price: number | undefined;
    pro_price: number | undefined;
}

export const initialState: ResourceResourcePriceState = {
    isLoading: false,
    error: undefined,
    price: undefined,
    pro_price: undefined
};

// GET /api/prices?type=<type>&resource_id=<resource_id>&single_result=true
export const getResourcePriceThunk = createAsyncThunk<
    ResourcePrice[],
    { resourceId: string; type: string },
    { rejectValue: Error }
>('ResourcePrice', async (params, thunkApi) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return request({
        url: `/api/prices?type=${params.type}&resource_id=${params.resourceId}&single_result=true`,
        method: 'GET'
    })
        .then((res: ApiResponse<ResourcePrice[]>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const resourcePriceSlice = createSlice({
    name: 'resourcePrice',
    initialState: initialState,
    reducers: {
        setResourcePrice: (state, action: PayloadAction<ResourcePrice>) => {
            state.price = action.payload.price;
            state.pro_price = action.payload.pro_price;
        },
        resetResourcePrice: (state) => {
            state.price = undefined;
            state.pro_price = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getResourcePriceThunk.fulfilled, (state, { payload }: PayloadAction<ResourcePrice[]>) => {
            const newResourcePrice = parseFloat(payload[0].price);
            const newPROResourcePrice = parseFloat(payload[0].pro_price);
            (state.isLoading = false),
                (state.error = undefined),
                ((state.price = limitDecimalPlaces(newResourcePrice, 2)),
                (state.pro_price = limitDecimalPlaces(newPROResourcePrice, 2)));
        });
    }
});

export const { setResourcePrice, resetResourcePrice } = resourcePriceSlice.actions;
