import { combineReducers } from 'redux';
import { loaderReducer } from './loader';
import { formProgressReducer } from './formProgress';
import { userInputReducer } from './userInput';
import { userReducer } from './user';
import { paymentReducer } from './payment';
import { geolocationReducer } from './geolocation';
import { discountReducer } from './discount';
import { questionAnswerReducer } from './questionAnswer';
import { defenseReducer } from './defense';
import { resourcePriceReducer } from './price';
import { translationReducer } from './translation';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    loader: loaderReducer,
    formProgress: formProgressReducer,
    userInput: userInputReducer,
    user: userReducer,
    payment: paymentReducer,
    geolocation: geolocationReducer,
    discount: discountReducer,
    questionAnswer: questionAnswerReducer,
    defense: defenseReducer,
    resourcePrice: resourcePriceReducer,
    translation: translationReducer
});

export default rootReducer;
