import { combineReducers } from 'redux';
import { userInputSlice} from './userInput.slice';

export const userInputReducer = combineReducers({
    userInput: userInputSlice.reducer,
});

export const userInputActions = {
    ...userInputSlice.actions
};
