import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import logo from '@/assets/images/logo.png';


const Header = () => {

    return (
        <header className={styles.header}>
            <Link to="/">
                <img src={logo} alt="Logo" className={styles.logo} />
            </Link>
        </header>
    );
};

export default Header;