import { combineReducers } from 'redux';
import { resourcePriceSlice } from './resourcePrice.slice';

export const resourcePriceReducer = combineReducers({
    resourcePrice: resourcePriceSlice.reducer
});

export const resourcePriceActions = {
    ...resourcePriceSlice.actions
};
