import { useEffect, useState, ChangeEvent } from 'react';
import styles from './TextArea.module.scss';

interface TextAreaProps {
    label?: string;
    name?: string;
    placeholder?: string;
    value?: string;
    onChange?: (newValue: string) => void;
    [x: string]: any;
}

const TextArea = ({ value: propValue, onChange, ...props }: TextAreaProps) => {
    const [textAreaValue, setTextAreaValue] = useState<string>(propValue ?? '');

    useEffect(() => {
        setTextAreaValue(propValue ?? '');
    }, [propValue]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;

        // setTextAreaValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <textarea
            className={styles.textArea}
            lang="es-ES"
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value ?? '')}
            onBlur={handleChange}
            {...props}

            rows={4} // tamaño inicial
        />
    );
};

export default TextArea;