import { useEffect, useState, ChangeEvent } from 'react';
import styles from './Input.module.scss';
import { isValidEmail } from '@/utils/helpers/utils.helper';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons for view/hide button
import { useMediaQuery } from 'react-responsive';

interface InputProps {
    label?: string;
    name?: string;
    type?: 'text' | 'number' | 'email' | 'password' | 'checkbox';
    placeholder?: string;
    value?: string;
    onChange?: (newValue: string) => void;
    [x: string]: any;
}

const Input = ({ value: propValue, type, onChange, ...props }: InputProps) => {
    const isMobile = useMediaQuery({ maxWidth: 480 });
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });

    const [inputValue, setInputValue] = useState<string | number>(propValue ?? '');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState(type === 'password' ? true : false);

    useEffect(() => {
        setInputValue(propValue ?? '');
    }, [propValue]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (type === 'password') setReadOnly(true);

        // Validate email format if the type is "email"
        if (type === 'email') {
            setIsValid(isValidEmail(newValue));
        }

        // setInputValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value ?? '';
        setInputValue(newValue);
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const getViewHideButtonSize = () => {
        if (isMobile) return '15px';
        if (isTablet) return '20px';
        return '25px';
    };


    return (
        <div className={`${isValid ? styles.inputWrapper : styles.inputWrapperError} ${!isValid ? styles.error : ''}`}>
            <input
                readOnly={readOnly}
                onFocus={() => setReadOnly(false)}
                className={`${styles.input} ${!props.disabled ? styles.inputHoverAndFocus : ''}`}
                lang="es-ES"
                value={inputValue}
                onChange={type === 'email' || type === 'checkbox' ? handleChange : handleInputValueChange}
                onBlur={type !== 'checkbox' ? handleChange : undefined}
                type={isPasswordVisible ? 'text' : type}
                {...props}
            />
            {type === 'password' && ( // Add view/hide button for password input
                <button
                    type="button"
                    className={styles.viewHideButton}
                    onClick={togglePasswordVisibility}
                >
                    {isPasswordVisible ? <FiEyeOff size={getViewHideButtonSize()} /> : <FiEye size={getViewHideButtonSize()} />}
                </button>
            )}
            {!isValid && type === 'email' && (
                <p className={styles.errorText}>El correo electrónico no es válido.</p>
            )}
        </div>
    );
};

export default Input;