import { useEffect } from 'react';
import Spinner from '../Spinner/Spinner';
import styles from './Loader.module.scss';
interface LoaderProps {
    isLoading: boolean;
}
const Loader = ({ isLoading }: LoaderProps) => {
    //window.scrollTo(0, 0);
    function noScroll() {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        if (isLoading) window.addEventListener('scroll', noScroll);

        return () => window.removeEventListener('scroll', noScroll);
    }, [isLoading]);
    return (
        <div className={isLoading ? styles.container : ''}>
            <Spinner isLoading={isLoading} />
        </div>
    );
};

export default Loader;
