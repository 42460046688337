import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrentStep,
    selectTermsAccepted
} from '@/store/formProgress/formProgress.selectors';
import { addStep, setCurrentStep } from '@/store/formProgress/formProgress.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/helpers/localStorage.helper';
import {
    patchUserInputThunk,
    postUserInputThunk,
    setUserInput
} from '@/store/userInput/userInput.slice';
import { selectUserInput } from '@/store/userInput/userInput.selectors';
import { selectUser } from '@/store/user/user.selectors';
import { useAppDispatch } from '@/index';
import { postUserThunk, setUser } from '@/store/user/user.slice';
import { isEmptyObject, isValidEmail } from '@/utils/helpers/utils.helper';
import { selectPaymentMethod } from '@/store/payment/payment.selectors';
import { UserInput } from '@/models/user_input';
import { Sections } from '@/utils/constants/wordsLimits';
import { Features } from '@/utils/constants/features';
import { selectQuestionAnswer } from '@/store/questionAnswer/questionAnswer.selectors';
import {
    patchQuestionAnswerThunk,
    postQuestionAnswerThunk,
    setQuestionAnswer
} from '@/store/questionAnswer/questionAnswer.slice';
import { patchDefenseThunk, postDefenseThunk, setDefense } from '@/store/defense/defense.slice';
import { selectDefense } from '@/store/defense/defense.selectors';
import { QuestionAnswer } from '@/models/question_answer';
import { Defense } from '@/models/defense';
import { selectTranslation } from '@/store/translation/translation.selectors';
import {
    patchTranslationThunk,
    postTranslationThunk,
    setTranslation
} from '@/store/translation/translation.slice';
import { Translation } from '@/models/translation';

const FeatureLayoutController = (feature: Features) => {
    const dispatch = useDispatch();
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector(selectUser);
    const userInput = useSelector(selectUserInput);
    const questionAnswer = useSelector(selectQuestionAnswer);
    const defense = useSelector(selectDefense);
    const currentStep = useSelector(selectCurrentStep);
    const paymentMethod = useSelector(selectPaymentMethod);
    const termsAccepted = useSelector(selectTermsAccepted);
    const translation = useSelector(selectTranslation);

    const [disabledNext, setDisabledNext] = useState<boolean>(true);

    const updateUser = async () => {
        let user_id = user?.id;
        if (!user_id) {
            const res: any = await appDispatch(postUserThunk({ user }));
            if (res.error) {
                return;
            }

            user_id = res.payload?.id;
        }

        // Si el usuario se ha registrado correctamente, actualizamos el recurso en el backend
        let resPatch: any = null;
        switch (feature) {
            case Features.SCIENTIFIC_TEXT:
                resPatch = await appDispatch(
                    patchUserInputThunk({
                        userInput: { ...userInput, user_id: user_id ?? '' } as UserInput
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
            case Features.QUESTION_ANSWER:
                resPatch = await appDispatch(
                    patchQuestionAnswerThunk({
                        questionAnswer: { ...questionAnswer, user_id: user_id ?? '' } as QuestionAnswer
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
            case Features.DEFENSE:
                resPatch = await appDispatch(
                    patchDefenseThunk({
                        defense: { ...defense, user_id: user_id ?? '' } as Defense
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
            case Features.TRANSLATION:
                resPatch = await appDispatch(
                    patchTranslationThunk({
                        translation: { ...translation, user_id: user_id ?? '' } as Translation
                    })
                );
                if (resPatch?.error) {
                    return;
                }
                break;
        }
    };

    const updateUserInput = async () => {
        let res: any = undefined;
        if (!userInput?.id) {
            res = await appDispatch(postUserInputThunk({ userInput }));
        } else {
            res = await appDispatch(patchUserInputThunk({ userInput }));

            if (res?.error) {
                return;
            }
        }
    };

    const updateQuestionAnswer = async () => {
        let res: any = undefined;
        if (!questionAnswer?.id) {
            res = await appDispatch(postQuestionAnswerThunk({ questionAnswer }));
        } else {
            res = await appDispatch(patchQuestionAnswerThunk({ questionAnswer }));

            if (res?.error) {
                return;
            }
        }
    };

    const updateDefense = async () => {
        let res: any = undefined;
        if (!defense?.id) {
            res = await appDispatch(postDefenseThunk({ defense }));
        } else {
            res = await appDispatch(patchDefenseThunk({ defense }));

            if (res?.error) {
                return;
            }
        }
    };

    const updateTranslation = async () => {
        let res: any = undefined;
        if (!translation?.id) {
            res = await appDispatch(postTranslationThunk({ translation }));
        } else {
            res = await appDispatch(patchTranslationThunk({ translation }));

            if (res?.error) {
                return;
            }
        }
    };

    const parseCurrentStep = () => {
        const path = location.pathname;
        switch (path) {
            case `/${feature}/study-params`:
                return 2;
            case `/${feature}/checkout`:
                return feature === Features.SCIENTIFIC_TEXT ? 3 : 2;
            default:
                return 1;
        }
    };

    const getNextStep = () => {
        switch (currentStep) {
            case 1:
                const nextStep = feature === Features.SCIENTIFIC_TEXT ? 'study-params' : 'checkout';
                return `/${feature}/${nextStep}`;
            case 2:
                return feature === Features.SCIENTIFIC_TEXT
                    ? `/${feature}/checkout`
                    : `/payment/${feature}`;
            case 3:
                return `/payment/${feature}`;
            default:
                return `/${feature}`;
        }
    };

    const getPreviousStep = () => {
        switch (currentStep) {
            case 3:
                return `/${Features.SCIENTIFIC_TEXT}/study-params`;
            default:
                return `/${feature}`;
        }
    };

    const checkDisabledNext = () => {
        const isPaymentReady =
            !user?.first_name ||
            !user?.last_name ||
            !user?.email ||
            !isValidEmail(user?.email) ||
            !paymentMethod ||
            !termsAccepted;

        console.log(defense);

        switch (currentStep) {
            case 1:
                switch (feature) {
                    case Features.SCIENTIFIC_TEXT:
                        return !userInput?.text_type || !userInput?.title;
                    case Features.QUESTION_ANSWER:
                        return !questionAnswer.original_text && !questionAnswer.original_image;
                    case Features.DEFENSE:
                        return (
                            !defense.question ||
                            !defense.possible_answers ||
                            !defense.teacher_answer_id ||
                            !defense.student_answer_id
                        );
                    case Features.TRANSLATION:
                        return !translation.original_text || !translation.target_language;
                    default:
                        return true;
                }
            case 2:
                return feature === Features.SCIENTIFIC_TEXT
                    ? !userInput?.study_type ||
                          isEmptyObject(userInput?.other_inputs?.words) ||
                          Object.keys(userInput?.other_inputs?.words).every((e) =>
                              [Sections.BIBLIOGRAPHY].includes(e as Sections)
                          )
                    : isPaymentReady;
            case 3:
                return isPaymentReady;
            default:
                return true;
        }
    };

    const handleNext = async () => {
        // FIXME
        if (checkDisabledNext()) {
            return;
        }

        // Actualizamos los pasos completados
        dispatch(addStep(currentStep));

        if (feature === Features.SCIENTIFIC_TEXT) {
            // Guardamos el userInput en localStorage
            setLocalStorageItem('userInput', userInput);

            // Si vamos a pasar al último paso, tenemos que enviar el userInput al backend
            // Si el userInput guardado en redux ya tiene id, significa que ya se había enviado al backend anteriormente
            // y no es necesario volver a crearlo, pero sí editarlo
            if (currentStep === 2) {
                await updateUserInput();
            }
            
            // Si estamos en el último paso, lo que se envía al backend es el user (datos personales)
            // Si el user guardado en redux ya tiene id, significa que ya se había enviado al backend anteriormente
            // y no es necesario volver a enviarlo
            else if (currentStep === 3) {
                await updateUser();
            }

        } else if (feature === Features.QUESTION_ANSWER) {
            // Guardamos el questionAnswer en localStorage
            setLocalStorageItem('questionAnswer', questionAnswer);

            if (currentStep === 1) {
                await updateQuestionAnswer();
            } else if (currentStep === 2) {
                await updateUser();
            }
        } else if (feature === Features.DEFENSE) {
            // Guardamos el defense en localStorage
            setLocalStorageItem('defense', defense);

            if (currentStep === 1) {
                await updateDefense();
            } else if (currentStep === 2) {
                await updateUser();
            }
        } else if (feature === Features.TRANSLATION) {
            // Guardamos el translation en localStorage
            setLocalStorageItem('translation', translation);

            if (currentStep === 1) {
                await updateTranslation();
            } else if (currentStep === 2) {
                await updateUser();
            }
        }
        
        navigate(getNextStep());
    };

    const handleBack = () => {
        dispatch(addStep(currentStep));
        navigate(getPreviousStep());
    };

    useEffect(() => {
        setDisabledNext(checkDisabledNext());
    }, [currentStep, userInput, questionAnswer, defense, user, paymentMethod, termsAccepted, translation]);

    useEffect(() => {
        dispatch(setCurrentStep(parseCurrentStep()));
    }, [location.pathname]);

    useEffect(() => {
        // Comprobamos si hay userInput, questionAnswer, defense y user en localStorage
        const savedUserInput = getLocalStorageItem('userInput');
        if (savedUserInput) {
            dispatch(setUserInput(savedUserInput));
        }

        const savedQuestionAnswer = getLocalStorageItem('questionAnswer');
        if (savedQuestionAnswer) {
            dispatch(setQuestionAnswer(savedQuestionAnswer));
        }

        const savedTranslation = getLocalStorageItem('translation');
        if (savedTranslation) {
            dispatch(setTranslation(savedTranslation));
        }

        //TODO activar aquí y cargar datos correctamente en la pantalla de impugnación
        /* const savedDefense = getLocalStorageItem('defense');
        if (savedDefense) {
            dispatch(setDefense(savedDefense));
        } */

        const savedUser = getLocalStorageItem('user');
        if (savedUser) {
            dispatch(setUser(savedUser));
        }
    }, []);

    return { currentStep, disabledNext, handleNext, handleBack };
};

export default FeatureLayoutController;
