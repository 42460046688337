import { selectUserInput } from '@/store/userInput/userInput.selectors';
import { resetWordsLimits, updateField } from '@/store/userInput/userInput.slice';
import { useDispatch, useSelector } from 'react-redux';

const TextInfoController = () => {
    const dispatch = useDispatch();

    const userInput = useSelector(selectUserInput);

    const handleTextTypeChange = (newValue: any) => {
        dispatch(updateField({ field: "text_type", newValue }));
        dispatch(resetWordsLimits());
    };

    const handleInputChange = (newValue: any, field: string) => {
        dispatch(updateField({ field: field, newValue }));
    };

    return { userInput, handleTextTypeChange, handleInputChange };
};

export default TextInfoController;
