import Button from '@/components/Button/Button';
import styles from './Success.module.scss';
import Envelope from '@/assets/images/envelope.svg';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { resetUserInput } from '@/store/userInput/userInput.slice';
import { resetUser } from '@/store/user/user.slice';
import { resetDiscount } from '@/store/discount/discount.slice';
import { resetQuestionAnswer } from '@/store/questionAnswer/questionAnswer.slice';
import { resetDefense } from '@/store/defense/defense.slice';
import { resetPayment } from '@/store/payment/payment.slice';
import { resetGeolocation } from '@/store/geolocation/geolocation.slice';
import { resetTranslation } from '@/store/translation/translation.slice';


const Success = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetUserInput());
        dispatch(resetUser());
        dispatch(resetDiscount());
        dispatch(resetQuestionAnswer());
        dispatch(resetDefense());
        dispatch(resetPayment());
        dispatch(resetGeolocation());
        dispatch(resetTranslation());
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.image}>
                    <Envelope />
                </div>
                <div className={styles.text}>
                    <h1>
                        ¡Genial!
                    </h1>
                    <p>
                        Ya tenemos todo lo necesario para ayudarte con tu trabajo.
                        <br />
                        En menos de 4 horas tendrás en tu correo electrónico el documento completo.
                        <br />
                        <small>
                            (Revisa la carpeta de SPAM)
                        </small>
                    </p>
                </div>
                <div className={styles.button}>
                    <Button variant={ButtonVariants.TERCIARY} size={ButtonSizes.LARGE} onClick={() => navigate('/')}>
                        Volver al inicio
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Success;
