import styles from "./StudyParams.module.scss";
import Input from "@/components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInput } from "@/store/userInput/userInput.selectors";
import { resetWordsLimits, updateField } from "@/store/userInput/userInput.slice";
import ReduxSelect from "@/components/Select/ReduxSelect";
import { Sections, WORDS_LIMIT } from "@/utils/constants/wordsLimits";
import useTranslator from "@/utils/hooks/useTranslator.hook";
import { useEffect, useState } from "react";

interface EnabledSections {
    [key: string]: boolean;
}

const StudyParams = () => {
    const dispatch = useDispatch();
    const { t } = useTranslator();

    const userInput = useSelector(selectUserInput);

    const [enabledSections, setEnabledSections] = useState<EnabledSections>({});

    const handleStudyTypeChange = (newValue: any) => {
        dispatch(updateField({ field: "study_type", newValue }));
        dispatch(resetWordsLimits());
    };

    const handleInputChange = (newValue: any, field: string) => {
        dispatch(updateField({ field, newValue }));
    };

    const handleCheckboxChange = (section: string) => {
        setEnabledSections(prevState => ({
            ...prevState,
            [section]: section in prevState ? !prevState[section] : false
        }));
    };

    useEffect(() => {
        let newOtherInputs = { words: {} };
        for (const section in enabledSections) {
            if (enabledSections[section]) {
                newOtherInputs.words[section] = userInput?.other_inputs?.words?.[section] ?? {};
            }
        }
        dispatch(updateField({ field: "other_inputs", newValue: newOtherInputs }));
    }, [enabledSections]);

    useEffect(() => {
        const newEnabledSections = {};
        if (userInput.text_type && userInput.study_type) {
            for (const section in WORDS_LIMIT[userInput.text_type][userInput.study_type]) {
                newEnabledSections[section] = section === Sections.BIBLIOGRAPHY || section in userInput?.other_inputs?.words;
            }
        }
        setEnabledSections(newEnabledSections);
    }, [userInput.study_type]);

    return (
        <div className={styles.content}>
            <div className={styles.type}>
                <ReduxSelect
                    name="study-type"
                    placeholder="Elige el tipo de estudio"
                    options={[
                        // provisional, se recuperarán del API
                        { value: "review", label: "Revisiones bibliográficas. sistemáticas o literarias" },
                        { value: "clinical_case", label: "Casos clínicos" },
                        // { value: "research", label: "Proyectos de investigación" },
                        // { value: "care_plan", label: "Planes de cuidados" },
                    ]}
                    value={userInput.study_type}
                    onChange={(newValue) => handleStudyTypeChange(newValue)}
                />
            </div>
            <div className={styles.wordsContainer}>
                {userInput?.text_type && userInput?.study_type && (
                    <>
                        <div>Seleccione todas las secciones que considere para su trabajo científico.</div>
                        <br />
                        <br />
                    </>
                )}
                <div className={styles.words}>
                    {userInput?.text_type && userInput?.study_type && (
                        Object.keys(WORDS_LIMIT[userInput.text_type][userInput.study_type]).map(section => (
                            <div key={section} className={styles.section}>
                                {userInput.text_type && userInput.study_type/*  && section !== Sections.BIBLIOGRAPHY */ && (
                                    <>
                                        <div>
                                            <Input
                                                type="checkbox"
                                                id={section}
                                                checked={section in enabledSections ? enabledSections[section] : false}
                                                onChange={() => handleCheckboxChange(section)}
                                                disabled={section === Sections.BIBLIOGRAPHY}
                                            />
                                        </div>
                                        <div className={`${styles.label} ${!(section in enabledSections ? enabledSections[section] : false) ? styles.disabledSectionLabel : ''}`}>
                                            {t(section)}
                                        </div>
                                        <div className={styles.inputNumbers}>
                                            <Input
                                                name={`words-${section}-min`}
                                                type="number"
                                                placeholder={(WORDS_LIMIT[userInput.text_type][userInput.study_type] as any)[section]?.min ?? 0}
                                                value={userInput.other_inputs?.words?.[section]?.min}
                                                onChange={(newValue) => handleInputChange(newValue, `other_inputs.words.${section}.min`)}
                                                disabled={!(section in enabledSections ? enabledSections[section] : false)}
                                            />
                                            <span> - </span>
                                            <Input
                                                name={`words-${section}-max`}
                                                type="number"
                                                placeholder={(WORDS_LIMIT[userInput.text_type][userInput.study_type] as any)[section]?.max ?? 0}
                                                value={userInput.other_inputs?.words?.[section]?.max}
                                                onChange={(newValue) => handleInputChange(newValue, `other_inputs.words.${section}.max`)}
                                                disabled={!(section in enabledSections ? enabledSections[section] : false)}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default StudyParams;