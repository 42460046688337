import TextArea from '@/components/TextArea/TextArea';
import styles from './QuestionsAnswers.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestionAnswer } from '@/store/questionAnswer/questionAnswer.selectors';
import { updateQuestionAnswerOriginalImage, updateQuestionAnswerOriginalText } from '@/store/questionAnswer/questionAnswer.slice';
import Input from '@/components/Input/Input';
import { useEffect, useState } from 'react';
import Upload from 'rc-upload';
import { Close, FileUpload } from '@mui/icons-material';

export default function QuestionsAnswers() {
    const dispatch = useDispatch();

    const questionAnswer = useSelector(selectQuestionAnswer);

    const [inputIsText, setInputIsText] = useState<boolean | undefined>(questionAnswer.original_image ? false : true);
    const [imgData, setImgData] = useState<string | undefined>(questionAnswer.original_image);

    const handleInputChange = (text: string | undefined) => {
        dispatch(updateQuestionAnswerOriginalText(text));
        if (text) {
            handleImageChange(undefined);
        }
    };

    const handleImageChange = (image: string | undefined) => {
        dispatch(updateQuestionAnswerOriginalImage(image));
        if (image) {
            handleInputChange(undefined);
        }
    };

    const beforeUpload = (file: any) => {
        if (file.type === "image/png") {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleImageChange(reader.result);
            };
            reader.readAsDataURL(file);
        }
        return false;
    };

    useEffect(() => {
        setImgData(questionAnswer.original_image);
        if (questionAnswer.original_image) {
            setInputIsText(false);
        } else {
            setInputIsText(true);
        }
    }, [questionAnswer.original_image]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {inputIsText ? (
                    <TextArea
                        name="questions-answers"
                        placeholder="Escribe las preguntas y respuestas aquí"
                        value={questionAnswer.original_text}
                        onChange={(text: string) => handleInputChange(text)}
                        rows={30}
                    />
                ) : (
                    <div className={styles.uploadArea}>
                        {imgData ? (
                            <div className={styles.uploadImage}>
                                <img src={imgData} alt="uploaded" width="50" />
                                <Close onClick={() => handleImageChange(undefined)} />
                            </div>
                        ) : (
                            <Upload
                                type='drag'
                                accept='image/*'
                                beforeUpload={beforeUpload}
                                style={{ width: '100%', height: '130px' }}
                            >

                                <div className={styles.uploadText}>
                                    <FileUpload /><p>Selecciona o arrastra aquí<br />el archivo de la imagen</p>
                                </div>

                            </Upload>
                        )
                        }
                    </div>
                )}
                <div className={styles.checkboxContainer}>
                    <div className={styles.checkboxOption}>
                        <Input
                            type="checkbox"
                            id="type-text"
                            checked={inputIsText}
                            onChange={() => setInputIsText(!inputIsText)}
                        />
                        <label htmlFor="type-text">Introducir texto</label>
                    </div>
                    <div className={styles.checkboxOption}>
                        <Input
                            type="checkbox"
                            id="type-image"
                            checked={!inputIsText}
                            onChange={() => setInputIsText(!inputIsText)}
                        />
                        <label htmlFor="type-image">Subir imagen</label>
                    </div>
                </div>
            </div>
        </div>
    );
}
