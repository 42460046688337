import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCompletedSteps } from '@/store/formProgress/formProgress.selectors';

const RouteGuard = ({ children, step, redirect }) => {
    const navigate = useNavigate();
    const completedSteps = useSelector(selectCompletedSteps);

    useEffect(() => {
        if (!completedSteps.includes(step)) {
            navigate(redirect);
        }
    }, [completedSteps, navigate, redirect, step]);

    return completedSteps.includes(step) ? children : null;
};

export default RouteGuard;